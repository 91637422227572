import { Controller } from "@hotwired/stimulus"
import ApexCharts from 'apexcharts'


export default class extends Controller {
  static targets = ["chart", "period", "customStartDate", "customEndDate", "filterWrapper", "noDataMessage"]
  static values = { deploymentId: String }

  connect() {
    this.chart = null
    this.fetchData()
  }

  async handleFilterChange(event) {
    if (event.target === this.periodTarget) {
      this.updateCustomDateVisibility()
    }

    await this.fetchData()
  }

  updateCustomDateVisibility() {
    const customDateRange = document.getElementById('custom-date-range')
    const customEndDate = document.getElementById('custom-end-date')
    if (this.periodTarget.value === 'custom') {
      customDateRange.classList.remove('hidden')
      customEndDate.classList.remove('hidden')
    } else {
      customDateRange.classList.add('hidden')
      customEndDate.classList.add('hidden')
    }
  }

  getFilterParams() {
    const params = new URLSearchParams()
    params.append('period', this.periodTarget.value)
    if (this.periodTarget.value === 'custom') {
      params.append('start_date', this.customStartDateTarget.value)
      params.append('end_date', this.customEndDateTarget.value)
    }
    return params
  }

  async fetchData() {
    const params = this.getFilterParams()
    try {
      const response = await fetch(`/crewai_plus/deployments/${this.deploymentIdValue}/quality_sampling/test_data?${params}`)
      const data = await response.json()
      const crewVersionsResponse = await fetch(`/crewai_plus/deployments/${this.deploymentIdValue}/crew_executions_data/crew_versions?${params}`)
      const dataCrewVersions = await crewVersionsResponse.json()

      if (this.hasNoData(data)) {
        this.showNoDataMessage()
      } else {
        this.hideNoDataMessage()
        this.renderChart(data, dataCrewVersions)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
      this.showNoDataMessage()
    }
  }

  hasNoData(data) {
    return !data || !data.labels || data.scores.length === 0
  }

  showNoDataMessage() {
    this.chartTarget.classList.add('hidden')
    this.noDataMessageTarget.classList.remove('hidden')
  }

  hideNoDataMessage() {
    this.chartTarget.classList.remove('hidden')
    this.noDataMessageTarget.classList.add('hidden')
  }

  renderChart(data, dataCrewVersions) {
    if (!data || !data.labels || !data.scores || !data.tokens) {
      console.error('Invalid data structure:', data)
      return
    }

    const scoresMap = new Map(data.scores.map(item => [item.date, item.scores]));
    const filledScores = data.labels.map(date => ({
      date,
      scores: scoresMap.get(date) || []
    }));

    const averageScoresData = filledScores.map(item => ({
      x: item.date,
      y: item.scores.length ? this.calculateAverage(item.scores.map(Number)) : 0
    }));

    const tokensMap = new Map(data.tokens.map(item => [item.date, item.token_usage]));
    const averageTokensData = data.labels.map(date => ({
      x: date,
      y: tokensMap.has(date) ? this.calculateAverage(tokensMap.get(date).map(t => t["total_tokens"])) : null
    }));

    const crewVersionsDataPoints = data.labels.map((label, index) => {
      const originalLabel = data.labels[index];
      const versionIndex = dataCrewVersions.labels.indexOf(originalLabel);
      return {
        x: label,
        y: versionIndex !== -1 ? 0 : null,
        version: versionIndex !== -1 ? dataCrewVersions.crew_versions[versionIndex] : null
      };
    });

    const markers = averageScoresData.map((item, index) => ({
      seriesIndex: 0,
      dataPointIndex: index,
      size: (item.y > 0) ? 10 : 0,
      fillColor: '#EB6658',
      strokeColor: '#EB6658',
    }));

    const options = {
      series: [
        {
          type: 'line',
          name: '',
          data: averageScoresData.map(item => item.y)
        },
        {
          name: 'Crew Versions',
          type: 'scatter',
          data: crewVersionsDataPoints.map(item => item.y),
        },
      ],
      chart: {
        height: 350,
        type: 'line',
        animations: {
          speed: 500
        }
      },
      colors: ['#d4526e', '#33b2df'],
      dataLabels: {
        enabled: false,
        offsetY: -20
      },
      fill: {
        opacity: [0.50]
      },
      stroke: {
        curve: 'straight',
        width: [2]
      },
      legend: {
        show: true,
        customLegendItems: ['Average Score'],
        inverseOrder: true
      },
      markers: {
        hover: {
          sizeOffset: 5
        },
        size: [8, 8],
        shape: ['circle', 'triangle'],
        colors: ['#EB6658', '#3147FF'],
        discrete: markers,
      },
      xaxis: {
        type: 'category',
        categories: data.labels,
        labels: {
          formatter: function(value) {
            return value;
          }
        }
      },
      yaxis: [
        {
          title: {
            text: 'Average Score'
          },
          min: 0.0,
          max: 10.0,
          tickAmount: 10,
          decimalsInFloat: 1,
          labels: {
            formatter: function (value) {
              if (value != null) {
                return value.toFixed(1);
              }
              return "null";
            }
          }
        },
      ],
      tooltip: {
        shared: false,
        intersect: true,
        x: {
          format: 'dd MMM yyyy'
        },
        y: {
          formatter: function (y, { seriesIndex, dataPointIndex, w }) {
            if (seriesIndex === 1) {
              return crewVersionsDataPoints[dataPointIndex].version ?
                `Crew Version: ${crewVersionsDataPoints[dataPointIndex].version}` :
                null;
            }
            if (y !== null) {
              const averageScore = y.toFixed(2);
              const totalTokens = averageTokensData[dataPointIndex].y;
              const crewVersion = crewVersionsDataPoints[dataPointIndex].version;
              return `
                <div class="custom-tooltip">
                  <span class="tooltip-score">Average Score: ${averageScore}</span>
                  <br/>
                  <span class="tooltip-tokens">Total Tokens: ${totalTokens !== null ? totalTokens.toLocaleString() : 'N/A'}</span>
                  ${crewVersion ? `<br/><span class="tooltip-version">Crew Version: ${crewVersion}</span>` : ''}
                </div>
              `;
            }
            return y;
          }
        },
      }
    };

    if (this.chart) {
      this.chart.updateOptions(options)
    } else {
      this.chart = new ApexCharts(this.chartTarget, options)
      this.chart.render()
    }
  }

  calculateAverage(scores) {
    return scores.reduce((sum, score) => sum + score, 0) / scores.length
  }
}
