import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["envVars", "valueField"]

  connect() {
    this.setupEventListeners()
  }

  setupEventListeners() {
    document.addEventListener('input', (event) => {
      if (event.target.tagName.toLowerCase() === 'textarea') {
        this.autoResize(event.target)
      }
    })
  }

  toggleValue(event) {
    const id = event.currentTarget.dataset.environmentVariablesIdParam
    const row = event.currentTarget.closest('tr')
    const valueDisplay = row.querySelector('.value-display')
    const valueInput = row.querySelector('.value-input')
    const eyeOpenIcon = event.currentTarget.querySelector('.icon-eye-open')
    const eyeClosedIcon = event.currentTarget.querySelector('.icon-eye-closed')

    if (valueDisplay.textContent === '********') {
      valueDisplay.textContent = valueInput.value
      eyeOpenIcon.classList.remove('hidden')
      eyeClosedIcon.classList.add('hidden')
    } else {
      valueDisplay.textContent = '********'
      eyeOpenIcon.classList.add('hidden')
      eyeClosedIcon.classList.remove('hidden')
    }
  }

  editVariable(event) {
    const id = event.currentTarget.dataset.environmentVariablesIdParam
    const row = document.querySelector(`tr[data-variable-id="${id}"]`)
    const keyDisplay = row.querySelector('.key-display')
    const keyInput = row.querySelector('.key-input')
    const valueDisplay = row.querySelector('.value-display')
    const valueInput = row.querySelector('.value-input')
    const editButton = row.querySelector('.edit-button')
    if (keyInput.classList.contains('hidden')) {
      keyDisplay.classList.add('hidden')
      keyInput.classList.remove('hidden')
      valueDisplay.classList.add('hidden')
      valueInput.classList.remove('hidden')
      editButton.textContent = 'Save'
      editButton.dataset.action = 'click->environment-variables#saveVariable'
    } else {
      this.saveVariable(event)
    }
  }

  saveVariable(event) {
    const id = event.currentTarget.dataset.environmentVariablesIdParam
    const row = document.querySelector(`tr[data-variable-id="${id}"]`)
    const keyInput = row.querySelector('.key-input')
    const valueInput = row.querySelector('.value-input')
    const keyDisplay = row.querySelector('.key-display')
    const valueDisplay = row.querySelector('.value-display')
    const editButton = row.querySelector('.edit-button')
    const eyeOpenIcon = row.querySelector('.icon-eye-open')
    const eyeClosedIcon = row.querySelector('.icon-eye-closed')

    // Simple frontend validation
    if (keyInput.value.trim() === '' || valueInput.value.trim() === '') {
      this.showFlashMessage('Both Key and Value must be filled', 'alert')
      return
    }

    fetch(`/crewai_plus/environment_variables/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({ environment_variable: { key: keyInput.value, value: valueInput.value } })
    }).then(response => response.json())
      .then(data => {
        if (data.success) {
          this.updateDisplay(keyDisplay, valueDisplay, keyInput, valueInput, editButton, eyeOpenIcon, eyeClosedIcon)
          if (data.flash && data.flash.notice) {
            this.showFlashMessage(data.flash.notice, 'notice')
          }
        } else {
          if (data.flash && data.flash.alert) {
            this.showFlashMessage(data.flash.alert, 'alert')
          } else {
            this.showFlashMessage('Failed to update the environment variable', 'alert')
          }
        }
      }).catch(error => {
        console.error('Error:', error)
        this.showFlashMessage('An error occurred while updating the environment variable', 'alert')
      })
  }

  updateDisplay(keyDisplay, valueDisplay, keyInput, valueInput, editButton, eyeOpenIcon, eyeClosedIcon) {
    keyDisplay.textContent = keyInput.value
    valueDisplay.textContent = '********'
    keyDisplay.classList.remove('hidden')
    keyInput.classList.add('hidden')
    valueDisplay.classList.remove('hidden')
    valueInput.classList.add('hidden')
    editButton.textContent = 'Edit'
    editButton.dataset.action = 'click->environment-variables#editVariable'
    eyeOpenIcon.classList.add('hidden')
    eyeClosedIcon.classList.remove('hidden')
  }

  autoResize(textarea) {
    textarea.style.height = 'auto'
    textarea.style.height = textarea.scrollHeight + 'px'
  }

  addEnvironmentVariable() {
    const envVarsContainer = this.envVarsTarget
    const newEnvVar = envVarsContainer.querySelector('.environment-variable').cloneNode(true)

    newEnvVar.querySelectorAll('input, textarea').forEach(input => {
      input.value = ''
    })

    const existingRemoveButton = newEnvVar.querySelector('.remove-env-var')
    if (existingRemoveButton) {
      existingRemoveButton.remove()
    }

    const removeButton = this.createRemoveButton()
    newEnvVar.appendChild(removeButton)

    envVarsContainer.appendChild(newEnvVar)

    this.updateRemoveButtons()
  }

  removeEnvironmentVariable(event) {
    const button = event.currentTarget
    const envVarsContainer = this.envVarsTarget
    const envVar = button.closest('.environment-variable')

    if (envVarsContainer.querySelectorAll('.environment-variable').length > 1) {
      envVar.remove()
    }

    this.updateRemoveButtons()
  }

  updateRemoveButtons() {
    const envVarsContainer = this.envVarsTarget
    const allEnvVars = envVarsContainer.querySelectorAll('.environment-variable')
    allEnvVars.forEach((envVar, index) => {
      const envVarRemoveButton = envVar.querySelector('.remove-env-var')
      if (index === 0) {
        if (envVarRemoveButton) {
          envVarRemoveButton.remove()
        }
      } else {
        if (!envVarRemoveButton) {
          const newRemoveButton = this.createRemoveButton()
          envVar.appendChild(newRemoveButton)
        }
      }
    })
  }

  createRemoveButton() {
    const removeButton = document.createElement('button')
    removeButton.type = 'button'
    removeButton.className = 'remove-env-var text-primary-color hover:text-primary-color-dark'
    removeButton.dataset.action = 'click->environment-variables#removeEnvironmentVariable'
    removeButton.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    `
    return removeButton
  }

  showValue(event) {
    event.target.type = 'text'
  }

  hideValue(event) {
    event.target.type = 'password'
  }

  showFlashMessage(message, type = 'notice') {
    const flashContainer = document.createElement('div')
    flashContainer.id = `flash-${type}`
    const baseClasses = 'flash-message fixed bottom-4 right-4 p-4 rounded shadow-lg z-50 text-white'
    const typeClasses = type === 'notice' ? 'bg-green-500' : 'bg-primary-color'
    flashContainer.className = `${baseClasses} ${typeClasses}`
    flashContainer.textContent = message

    document.body.appendChild(flashContainer)

    setTimeout(() => {
      flashContainer.classList.add('opacity-0', 'transition-opacity', 'duration-300')
      setTimeout(() => {
        document.body.removeChild(flashContainer)
      }, 300)
    }, 5000)
  }
}
