import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["pendingRunCount", "pendingHumanInputCount", "completedCount", "errorCount"]
  static values = { deploymentId: String }

  connect() {
    this.loadStatusCount()
  }

  loadStatusCount() {
    fetch(`/crewai_plus/deployments/${this.deploymentIdValue}/crew_executions/status_count`)
      .then(response => response.json())
      .then(data => {
        this.updateStatusCount(data)
      })
      .catch(error => console.error("Error loading status count:", error))
  }

  updateStatusCount(data) {
    this.pendingRunCountTarget.textContent = data.pending_run_count
    this.pendingHumanInputCountTarget.textContent = data.pending_human_input_count
    this.completedCountTarget.textContent = data.completed_count
    this.errorCountTarget.textContent = data.error_count
  }
}
