import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "spinner"]
  static values = { deploymentId: Number }

  save(event) {
    const input = event.target
    const taskId = input.dataset.taskId
    const manualCost = input.value

    this.showSpinner()

    fetch(`/crewai_plus/deployments/${this.deploymentIdValue}/crew_tasks/${taskId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ manual_cost: manualCost })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        this.hideSpinner()
      } else {
        this.hideSpinner()
        input.value = input.dataset.originalValue
      }
    })
    .catch(error => {
      console.error('Error:', error)
      this.hideSpinner()
      input.value = input.dataset.originalValue
    })
  }

  showSpinner() {
    this.spinnerTarget.classList.remove('hidden')
  }

  hideSpinner() {
    this.spinnerTarget.classList.add('hidden')
  }
}
