import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

export default class extends Controller {
  static values = { branchesApiUrl: String }
  static targets = ["valueField", "repo", "branch"];

  repoTargetConnected(target) {
    this.repoChoices = new Choices(target, {
      searchEnabled: true,
      itemSelectText: '',
      shouldSort: false,
      classNames: {
        containerInner: ['border', 'border-gray-300', 'block', 'w-full', 'rounded-md', 'border-0', 'py-0.5', 'pl-3', 'pr-10', 'text-gray-900', 'sm:text-sm', 'sm:leading-6']
      }
    })
  }

  branchTargetConnected(target) {
    this.branchChoices = new Choices(target, {
      searchEnabled: true,
      itemSelectText: '',
      shouldSort: false,
      classNames: {
        containerInner: ['border', 'border-gray-300', 'block', 'w-full', 'rounded-md', 'border-0', 'py-0.5', 'pl-3', 'pr-10', 'text-gray-900', 'sm:text-sm', 'sm:leading-6']
      }
    });
  }

  async updateRepo(event) {
    this.branchChoices.removeActiveItems();

    await this.branchChoices.setChoices(async () => {
      const repoName = event.target.options[event.target.selectedIndex].text;
      if (!repoName) { return [] }

      const query = new URLSearchParams({repo: repoName});
      const apiUrl = `${this.branchesApiUrlValue}?${query}`;

      const response = await fetch(apiUrl);
      const branches = await response.json();

      const formattedBranches = branches
        .sort((a, b) => {
          if (a === "main" || a === "master") return -1;
          if (b === "main" || b === "master") return 1;
          return a.localeCompare(b);
        })
        .map(branch => ({ value: branch, label: branch }));

      return formattedBranches;
    }, "value", "label", true);

    const firstChoice = this.branchChoices.choiceList.element.firstChild.innerText;
    this.branchChoices.setChoiceByValue(firstChoice);
  }
}
