import { Controller } from "@hotwired/stimulus"
import ApexCharts from 'apexcharts'
import { parseISO, parse } from 'date-fns';

export default class extends Controller {
  static values = { dataset: String, chart: String };
  static targets = ["spinner"];

  connect() {
    this.chart = null;
    this.updateChart();
  }

  updateChart(selectedTimeframe, selectedPeriod, selectedStartDate, selectedEndDate, selectedDistribution) {
    const timeframe = selectedTimeframe || document.querySelector('[name="timeframe"]').value;
    const period = selectedPeriod || document.querySelector('[name="period"]').value;
    const startDate = selectedStartDate || document.querySelector('[name="custom_start_date"]')?.value || '';
    const endDate = selectedEndDate || document.querySelector('[name="custom_end_date"]')?.value || '';
    const distribution = selectedDistribution || document.querySelector('[name="distribution"]').value;

    let url = `${this.datasetValue}?timeframe=${timeframe}&period=${period}&start_date=${startDate}&end_date=${endDate}&distribution=${distribution}`;
    this.showSpinner();

    fetch(url, {
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      this.renderChart(data, distribution);
    })
    .catch(error => console.error('Error:', error))
    .finally(() => {
      this.hideSpinner();
    });
  }

  renderChart(data, distribution) {
    const chartElement = document.getElementById(this.chartValue);
    if (this.chart) {
      this.chart.destroy();
    }

    let series;
    let options;

    switch (this.chartValue) {
      case "combined-chart":
        series = [
          {
            name: "Created",
            type: 'area',
            data: Object.entries(data.crews_created).map(([date, value]) => [new Date(date).getTime(), value])
          },
          {
            name: "Deploys",
            type: 'area',
            data: Object.entries(data.deploys).map(([date, value]) => [new Date(date).getTime(), value])
          },
          {
            name: "Executions",
            type: 'area',
            data: Object.entries(data.crew_executions).map(([date, value]) => [new Date(date).getTime(), value])
          }
        ];
        options = this.getCombinedChartOptions(series, distribution);
        break;
      case "crews-executions-by-deployment-chart":
        series = data.map(item => ({
          name: item.name,
          data: Object.entries(item.data).map(([date, value]) => [new Date(date).getTime(), value])
        }));
        options = this.getLineChartOptions(series, distribution);
        break;
      case "crews-per-user-chart":
        series = Object.entries(data).map(([name, value]) => ({ name, value }));
        options = this.getPieChartOptions(series);
        break;
      default:
        console.error("Unknown chart type");
        return;
    }

    this.chart = new ApexCharts(chartElement, options);
    this.chart.render();
  }

  getLineChartOptions(series, distribution) {
    return {
      chart: {
        type: 'line',
        height: 350,
        toolbar: {
          show: false
        }
      },
      series: series,
      xaxis: {
        type: 'datetime',
        labels: {
          format: 'dd MMM yyyy'
        },
        axisBorder: {
          show: true
        },
        axisTicks: {
          show: true
        },
        padding: {
          right: 10
        }
      },
      yaxis: {
        title: {
          text: distribution === 'cumulative' ? 'Cumulative Count' : 'Count'
        },
        max: function(max) {
          return max * 1.1; // Add 10% to the max value
        },
        min: 0,
        forceNiceScale: true,
        axisBorder: {
          show: true
        },
        axisTicks: {
          show: true
        }
      },
      colors: ['#EB6658', '#262626', '#3147FF'],
      stroke: {
        curve: 'smooth',
        width: 2
      },
      legend: {
        position: 'top'
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy'
        }
      }
    };
  }

  getAreaChartOptions(series, distribution) {
    return {
      chart: {
        type: 'area',
        height: 350,
        toolbar: {
          show: false
        }
      },
      series: series,
      xaxis: {
        type: 'datetime',
        labels: {
          format: 'dd MMM yyyy'
        },
        axisBorder: {
          show: true
        },
        axisTicks: {
          show: true
        },
        padding: {
          right: 10
        }
      },
      yaxis: {
        title: {
          text: distribution === 'cumulative' ? 'Cumulative Count' : 'Count'
        },
        max: function(max) {
          return max * 1.1; // Add 10% to the max value
        },
        min: 0,
        forceNiceScale: true,
        axisBorder: {
          show: true
        },
        axisTicks: {
          show: true
        }
      },
      colors: ['#EB6658', '#262626', '#3147FF'],
      stroke: {
        curve: 'smooth',
        width: 2
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.3,
          stops: [0, 90, 100]
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy'
        }
      },
      grid: {
        padding: {
          right: 30
        }
      }
    };
  }

  getChartTitle() {
    switch (this.chartValue) {
      case "crew-runs-chart":
        return "Crew Executions";
      case "crews-created-chart":
        return "Crews Created";
      case "deployment-instances-created-chart":
        return "Deploys";
      default:
        return "Data";
    }
  }

  showSpinner() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.remove('hidden');
    }
  }

  hideSpinner() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.add('hidden');
    }
  }

  getPieChartOptions(series) {
    return {
      chart: {
        type: 'pie',
        height: 350
      },
      series: series.map(item => item.value),
      labels: series.map(item => item.name),
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      colors: ['#EB6658', '#262626', '#3147FF', '#FFA500', '#008000', '#800080', '#FFC0CB'],
    };
  }

  getCombinedChartOptions(series, distribution) {
    return {
      chart: {
        type: 'area',
        height: 350,
        toolbar: {
          show: false
        }
      },
      series: series,
      colors: ['#EB6658', '#262626', '#4ECDC4'],
      xaxis: {
        type: 'datetime',
        labels: {
          format: 'dd MMM yyyy'
        },
        axisBorder: {
          show: true
        },
        axisTicks: {
          show: true
        }
      },
      yaxis: [
        {
          title: {
            text: "Creations",
            style: {
              color: '#EB6658'
            }
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#EB6658'
          },
          labels: {
            style: {
              colors: '#EB6658'
            }
          }
        },
        {
          opposite: true,
          title: {
            text: "Deploys",
            style: {
              color: '#262626'
            }
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#262626'
          },
          labels: {
            style: {
              colors: '#262626'
            }
          }
        },
        {
          opposite: true,
          title: {
            text: "Executions",
            style: {
              color: '#4ECDC4'
            }
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#4ECDC4'
          },
          labels: {
            style: {
              colors: '#4ECDC4'
            }
          }
        }
      ],
      fill: {
        type: ['gradient', 'gradient', 'gradient'],
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.75,
          opacityTo: 0.55,
          stops: [0, 95, 100]
        }
      },
      markers: {
        size: [3, 3, 3],
        shape: ['circle', 'circle', 'circle'],
        colors: ['#EB6658', '#262626', '#4ECDC4'],
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        position: 'bottom'
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy'
        },
        y: {
          formatter: function(value, { seriesIndex, dataPointIndex, w }) {
            return value;
          }
        }
      }
    };
  }
}
