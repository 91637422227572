import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.activateTab('charts') // Activate the first tab by default
  }

  handleTabClick(event) {
    const tabName = event.currentTarget.dataset.tab
    this.activateTab(tabName)
  }

  activateTab(tabName) {
    // Deactivate all tabs and content
    this.element.querySelectorAll('[data-action="click->usage-dashboard--tabs#handleTabClick"]').forEach(button => {
      button.classList.remove('text-gray-700', 'border-red-500')
      button.classList.add('text-gray-500', 'hover:text-gray-700')
    })
    this.element.querySelectorAll('[data-tab-content]').forEach(content => content.classList.add('hidden'))

    // Activate the selected tab and content
    const selectedTab = this.element.querySelector(`[data-action="click->usage-dashboard--tabs#handleTabClick"][data-tab="${tabName}"]`)
    selectedTab.classList.remove('text-gray-500', 'hover:text-gray-700')
    selectedTab.classList.add('text-gray-700', 'border-red-500')

    const selectedContent = this.element.querySelector(`[data-tab-content="${tabName}"]`)
    selectedContent.classList.remove('hidden')
  }
}
