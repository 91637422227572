import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.highlightJSON()
  }

  highlightJSON() {
    const elements = this.element.querySelectorAll('.syntax-highlight-enabled')
    elements.forEach(element => {
      try {
        const content = element.textContent
        const parsedJSON = JSON.parse(content)
        element.textContent = JSON.stringify(parsedJSON, null, 2)
        element.innerHTML = `<code class="language-json">${element.textContent}</code>`
        Prism.highlightElement(element.firstChild)
      } catch (e) {
        // If it's not valid JSON, leave it as is
      }
    })
  }
}
