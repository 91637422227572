import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="admin-granted-access"
export default class extends Controller {
  static targets = ["organization", "plan"];

  connect() {
    this.togglePlanField();
  }

  togglePlanField() {
    if (this.organizationTarget.value) {
      this.planTarget.disabled = true;
      this.planTarget.value = ""; // Clear the plan select value
    } else {
      this.planTarget.disabled = false;
    }
  }

  handleOrganizationChange() {
    this.togglePlanField();
  }
}