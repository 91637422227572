import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["filterWrapper"];

  connect() {
    console.log("event delegation controller connected");
  }

  handleSelectChange(event) {
    this.updateAllCharts();
  }

  handlePeriodChange(event) {
    const customDateRange = document.getElementById('custom-date-range');
    const customEndDate = document.getElementById('custom-end-date');
    if (event.target.value === 'custom') {
      customDateRange.classList.remove('hidden');
      customEndDate.classList.remove('hidden');
    } else {
      customDateRange.classList.add('hidden');
      customEndDate.classList.add('hidden');
    }
    this.updateAllCharts();
  }

  updateAllCharts() {
    const selectedTimeframe = this.element.querySelector('[name="timeframe"]').value;
    const selectedPeriod = this.element.querySelector('[name="period"]').value;
    const selectedStartDate = this.element.querySelector('[name="custom_start_date"]')?.value || '';
    const selectedEndDate = this.element.querySelector('[name="custom_end_date"]')?.value || '';
    const selectedDistribution = this.element.querySelector('[name="distribution"]').value;

    const chartUpdateElements = this.element.querySelectorAll("[data-controller='usage-dashboard--chart-update']");
    const chartUpdateControllers = Array.from(chartUpdateElements).map(element =>
      this.application.getControllerForElementAndIdentifier(element, "usage-dashboard--chart-update")
    ).filter(Boolean);

    chartUpdateControllers.forEach(controller => {
      controller.updateChart(selectedTimeframe, selectedPeriod, selectedStartDate, selectedEndDate, selectedDistribution);
    });
  }
}
