import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'
import 'chartjs-adapter-date-fns';
import { parseISO, parse } from 'date-fns';

export default class extends Controller {
  static targets = ["spinner"]
  static values = { deploymentId: Number }

  connect() {
    this.chart = null
    const initialParams = this.getInitialParams()
    this.fetchData(initialParams)
    this.element.addEventListener("filter-changed", this.handleFilterChange.bind(this))
  }

  showSpinner() {
    this.spinnerTarget.classList.remove('hidden')
  }

  hideSpinner() {
    this.spinnerTarget.classList.add('hidden')
  }

  async fetchDataTokens(params) {
    const response = await fetch(`/crewai_plus/deployments/${this.deploymentIdValue}/crew_executions_data/data?source=tokens&${new URLSearchParams(params)}`)
    return await response.json()
  }

  async fetchDataExecutionTime(params) {
    const response = await fetch(`/crewai_plus/deployments/${this.deploymentIdValue}/crew_executions_data/data?source=execution_time&${new URLSearchParams(params)}`)
    return await response.json()
  }

  async fetchDataCrewVersions() {
    const response = await fetch(`/crewai_plus/deployments/${this.deploymentIdValue}/crew_executions_data/crew_versions`)
    return await response.json()
  }

  async fetchDataSummary(params) {
    const response = await fetch(`/crewai_plus/deployments/${this.deploymentIdValue}/crew_executions_data/summary?${new URLSearchParams(params)}`)
    return await response.json()
  }

  async fetchData(params = {}) {
    this.showSpinner()
    const [dataTokens, dataExecutionTime, dataCrewVersions] = await Promise.all([
      this.fetchDataTokens(params),
      this.fetchDataExecutionTime(params),
      this.fetchDataCrewVersions(),
    ])
    this.renderChart(dataTokens, dataExecutionTime, dataCrewVersions, params.aggregation)
    this.hideSpinner()
  }

  renderChart(dataTokens, dataExecutionTime, dataCrewVersions, aggregation) {
    const ctx = document.getElementById('dualAxisChart').getContext('2d')
    const parsedLabels = this.parseLabels(dataTokens.labels)

    const crewVersionsDataPoints = parsedLabels.map((label, index) => {
      const originalLabel = dataTokens.labels[index];
      const versionIndex = dataCrewVersions.labels.indexOf(originalLabel);
      return {
        x: label,
        y: versionIndex !== -1 ? 0 : null,
        version: versionIndex !== -1 ? dataCrewVersions.crew_versions[versionIndex] : null
      };
    });

    if (this.chart) {
      this.chart.destroy()
    }

    const tokenTitle = aggregation === "total" ?
      'Total Prompt Tokens' :
      'Average Prompt Tokens'

    const executionTimeTitle = aggregation === "total" ?
      'Total Execution Time' :
      'Average Execution Time'

    const colorScheme = {
      average: {
        tokens: {
          borderColor: '#3B82F6',
          backgroundColor: 'rgba(59, 130, 246, 0.2)'
        },
        executionTime: {
          borderColor: '#22C55E',
          backgroundColor: 'rgba(34, 197, 94, 0.2)'
        }
      },
      total: {
        tokens: {
          borderColor: '#3B82F6',
          backgroundColor: 'rgba(59, 130, 246, 0.2)'
        },
        executionTime: {
          borderColor: '#22C55E',
          backgroundColor: 'rgba(34, 197, 94, 0.2)'
        }
      }
    }

    const selectedColorScheme = aggregation === "total" ? colorScheme.total : colorScheme.average
    this.chart = new Chart(ctx, {
      type: "line",
      data: {
        labels: parsedLabels,
        datasets: [
          {
            label: tokenTitle,
            data: dataTokens.data,
            borderColor: selectedColorScheme.tokens.borderColor,
            backgroundColor: selectedColorScheme.tokens.backgroundColor,
            yAxisID: 'y',
            tension: 0.1,
            fill: aggregation === "total" ? 'start' : false
          },
          {
            label: executionTimeTitle,
            data: dataExecutionTime.data,
            borderColor: selectedColorScheme.executionTime.borderColor,
            backgroundColor: selectedColorScheme.executionTime.backgroundColor,
            yAxisID: 'y1',
            tension: 0.1,
            fill: aggregation === "total" ? 'start' : false
          },
          {
            label: 'Crew Versions',
            type: 'scatter',
            data: crewVersionsDataPoints,
            borderColor: '#8B5CF6',
            backgroundColor: '#8B5CF6',
            pointStyle: 'triangle',
            pointRadius: 10,
            yAxisID: 'y'
          }
        ]
      },
      options: {
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: `${tokenTitle} and ${executionTimeTitle}`,
            font: {
              size: 20
            }
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                if (context.dataset.label === 'Crew Versions') {
                  return `New Version: ${crewVersionsDataPoints[context.dataIndex].version}`;
                }
                return context.dataset.label + ': ' + context.parsed.y;
              }
            }
          }
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: this.determineTimeUnit()
            },
            title: {
              display: true,
              text: 'Date'
            }
          },
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            beginAtZero: true,
            title: {
              display: true,
              text: 'Prompt Tokens'
            }
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            beginAtZero: true,
            title: {
              display: true,
              text: 'Execution Time (s)'
            },
            grid: {
              drawOnChartArea: false,
            },
          }
        }
      }
    })
  }

  parseLabels(labels) {
    const timeframe = this.timeFrameValue()
    return labels.map(label => {
      if (timeframe === 'week') {
        return parse(label, 'yyyy-MM-dd', new Date())
      } else if (timeframe === 'month') {
        return parse(label, 'yyyy-MM', new Date())
      } else {
        return parseISO(label)
      }
    })
  }

  determineTimeUnit() {
    switch (this.timeFrameValue()) {
      case 'week':
        return 'week'
      case 'month':
        return 'month'
      default:
        return 'day'
    }
  }

  timeFrameValue() {
    return document.querySelector('[data-crew-dashboard--chart-filter-delegator-target="timeframe"]').value
  }

  handleFilterChange(event) {
    this.fetchData(event.detail)
  }

  getInitialParams() {
    return {
      timeframe: document.querySelector('[data-crew-dashboard--chart-filter-delegator-target="timeframe"]').value,
      period: document.querySelector('[data-crew-dashboard--chart-filter-delegator-target="period"]').value,
      distribution: document.querySelector('[data-crew-dashboard--chart-filter-delegator-target="distribution"]').value,
      start_date: document.querySelector('[data-crew-dashboard--chart-filter-delegator-target="customStartDate"]')?.value,
      end_date: document.querySelector('[data-crew-dashboard--chart-filter-delegator-target="customEndDate"]')?.value,
      aggregation: document.querySelector('[data-crew-dashboard--chart-filter-delegator-target="aggregation"]').value,
    }
  }
}
