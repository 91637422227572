import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["name", "automationThoughts", "submit", "form", "errorMessage", "formContainer", "spinnerContainer"]
  static optionalTargets = ["onboardingContainer"]
  static values = {
    minNameLength: { type: Number, default: 2 },
    pollingInterval: { type: Number, default: 2000 } // 2 seconds
  }

  connect() {
    this.nameSubmitted = false
    this.profileResearchComplete = false
    this.formSubmitted = false
  }

  updateName(event) {
    const name = event.target.value.trim()
    if (name.length >= this.minNameLengthValue && !this.nameSubmitted) {
      this.kickoffResearch(name)
    }
  }

  updateProfile(event) {
    event.preventDefault()
    this.clearErrorMessage()
    this.formSubmitted = true

    const name = this.nameTarget.value.trim()
    const automationThoughts = this.automationThoughtsTarget.value.trim()

    if (name.length < this.minNameLengthValue) {
      this.showErrorMessage(`Name must be at least ${this.minNameLengthValue} characters long.`)
      return
    }

    this.setLoading(true)

    const userId = this.element.dataset.crewStudioOnboardingUserId
    const url = this.formTarget.action
    const body = { name: name, automation_thoughts: automationThoughts }

    this.submitRequest(url, 'PATCH', body, (data) => {
      if (!this.nameSubmitted) {
        this.kickoffResearch(name)
      } else if (this.profileResearchComplete) {
        this.submitFullProfile(name, automationThoughts)
      } else {
        this.showSpinner()
      }
    })
  }

  kickoffResearch(name) {
    const userId = this.element.dataset.crewStudioOnboardingUserId
    const url = `/crewai_plus/users/${userId}/kickoff_research`
    const body = { name: name }

    this.submitRequest(url, 'POST', body, (data) => {
      this.nameSubmitted = true
      if (this.formSubmitted) {
        this.showSpinner()
      }
      this.startPollingForProfileResearch()
    })
  }

  startPollingForProfileResearch() {
    this.pollingInterval = setInterval(() => {
      this.checkProfileResearch()
    }, this.pollingIntervalValue)
  }

  checkProfileResearch() {
    const userId = this.element.dataset.crewStudioOnboardingUserId
    const url = `/crewai_plus/users/${userId}`

    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      credentials: 'same-origin'
    })
    .then(response => response.json())
    .then(data => {
      if (data.user.profile_research) {
        this.profileResearchComplete = true
        clearInterval(this.pollingInterval)

        if (this.formSubmitted) {
          const name = this.nameTarget.value.trim()
          const automationThoughts = this.automationThoughtsTarget.value.trim()
          this.submitFullProfile(name, automationThoughts)
        } else {
          this.hideSpinner()
          this.setLoading(false)
        }
      }
    })
    .catch(error => {
      console.error('Error checking profile research:', error)
    })
  }

  submitFullProfile(name, automationThoughts) {
    if (!this.profileResearchComplete) {
      this.showSpinner()
      if (!this.pollingInterval) {
        this.startPollingForProfileResearch()
      }
      this.formSubmitted = true // Add this line to indicate the form was submitted
      return
    }

    this.setLoading(true)
    this.clearErrorMessage()

    const userId = this.element.dataset.crewStudioOnboardingUserId
    const url = `/crewai_plus/users/${userId}`
    const body = { user: { name: name, automation_thoughts: automationThoughts } }

    this.submitRequest(url, 'PATCH', body, (data) => {
      this.disableForm()
      this.redirectToMainUI()
    })
  }

  submitRequest(url, method, body, successCallback) {
    fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Accept': 'application/json'
      },
      body: JSON.stringify(body),
      credentials: 'same-origin'
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json()
    })
    .then(data => {
      successCallback(data)
    })
    .catch(error => {
      console.error('Error:', error)
      this.showErrorMessage("An error occurred. Please try again.")
    })
    .finally(() => {
      this.setLoading(false)
    })
  }

  setLoading(isLoading) {
    this.submitTarget.disabled = isLoading
    this.submitTarget.innerHTML = isLoading ? 'Initiating...' : 'Get Started'
    // Remove the following two lines:
    // this.nameTarget.disabled = isLoading
    // this.automationThoughtsTarget.disabled = isLoading
  }

  showErrorMessage(message) {
    this.errorMessageTarget.textContent = message
    this.errorMessageTarget.classList.remove('hidden')
    this.errorMessageTarget.classList.add('text-red-500')
  }

  clearErrorMessage() {
    this.errorMessageTarget.textContent = ''
    this.errorMessageTarget.classList.add('hidden')
  }

  disableForm() {
    this.formTarget.querySelectorAll('input, textarea, button').forEach(el => {
      el.disabled = true
    })
  }

  showSpinner() {
    this.formContainerTarget.classList.add('hidden')
    this.spinnerContainerTarget.classList.remove('hidden')
    this.spinnerContainerTarget.innerHTML = `
      <div class="flex flex-col items-center justify-center h-full">
        <div class="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
        <p class="text-center mt-4">Processing your profile...</p>
      </div>
    `
  }

  hideSpinner() {
    this.spinnerContainerTarget.classList.add('hidden')
    this.formContainerTarget.classList.remove('hidden')
  }

  redirectToMainUI() {
    window.location.reload()
  }
}
