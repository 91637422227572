import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu"]

  toggle() {
    if (this.menuTarget.classList.contains('hidden')) {
      this.menuTarget.classList.remove('hidden');
      this.menuTarget.classList.add('block', 'opacity-0', 'scale-95');
      setTimeout(() => {
        this.menuTarget.classList.remove('opacity-0', 'scale-95');
        this.menuTarget.classList.add('opacity-100', 'scale-100');
      }, 10); // start the animation
    } else {
      this.menuTarget.classList.remove('opacity-100', 'scale-100');
      this.menuTarget.classList.add('opacity-0', 'scale-95');
      setTimeout(() => {
        this.menuTarget.classList.add('hidden');
        this.menuTarget.classList.remove('block', 'opacity-0', 'scale-95');
      }, 10); // match the duration of leaving transition
    }
  }
}
