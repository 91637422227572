import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["planSelect", "contactSalesButton", "activateAccountLink", "planSelectDropdown", "agreedTermsContainer"]

  connect() {
    this.updateSalesButton()

    this.planSelectDropdownTarget.addEventListener('change', () => {
      this.updateSalesButton()
    })

  }

  updateSalesButton() {
    const selectedPlanId = JSON.parse(this.planSelectDropdownTarget.value)

    fetch(`/plans/${selectedPlanId}`)
      .then(response => response.json())
      .then(data => {
        this.showSalesButton()
      })
      .catch(error => {
        console.error('Error fetching plan data:', error)
        alert("An error occurred while fetching plan data. Please try again or contact support at support@crewai.com")
      })
  }

  showSalesButton() {
    this.contactSalesButtonTarget.classList.remove("hidden")
    this.activateAccountLinkTarget.classList.add("hidden")
    this.agreedTermsContainerTarget.classList.add("hidden")
  }

}
