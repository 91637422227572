import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="admin-affiliate"
export default class extends Controller {
  static targets = ["input", "button"];

  async copy() {
    try {
      await navigator.clipboard.writeText(this.inputTarget.value);
      this.showCopiedFeedback();
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  }

  showCopiedFeedback() {
    const originalText = this.buttonTarget.textContent;
    this.buttonTarget.textContent = "Copied!";
    this.buttonTarget.disabled = true;

    setTimeout(() => {
      this.buttonTarget.textContent = originalText;
      this.buttonTarget.disabled = false;
    }, 2000);
  }
}
