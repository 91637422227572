import { Controller } from "@hotwired/stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static targets = ["tasksExecutionTimeOnPeriodChart", "runsAndErrorsPerDayChart", "errorRatioPast30days"]
  static values = { deploymentId: Number }

  connect() {
    Promise.allSettled([
      this.fetchTasksExecutionTimeOnPeriod(),
      this.fetchRunsAndErrorsPerDay(),
    ])
  }

  fetchTasksExecutionTimeOnPeriod() {
    fetch(`/crewai_plus/deployments/${this.deploymentIdValue}/crew_executions_tasks_data/tasks_execution_time`)
      .then(response => response.json())
      .then(data => {
        this.renderTasksExecutionTimeOnPeriodChart(data)
      })
  }

  fetchRunsAndErrorsPerDay() {
    fetch(`/crewai_plus/deployments/${this.deploymentIdValue}/crew_executions_tasks_data/runs_and_errors_per_day`)
      .then(response => response.json())
      .then(data => {
        this.renderRunsAndErrorsPerDayChart(data)
      })
  }

  renderRunsAndErrorsPerDayChart(data) {
    const totalRuns = data.reduce((sum, item) => sum + item.total_runs, 0);
    const totalErrors = data.reduce((sum, item) => sum + item.total_errors, 0);
    const errorRatio = totalRuns > 0 ? totalErrors / totalRuns : 0;
    this.errorRatioPast30daysTarget.innerHTML =  errorRatio.toLocaleString()

    const options = {
      chart: {
        type: 'area',
        height: 250,
        stacked: false,
      },
      series: [
        {
          name: 'Runs',
          data: data.map(item => item.total_runs)
        },
        {
          name: 'Errors',
          data: data.map(item => item.total_errors)
        }
      ],
      xaxis: {
        categories: data.map(item => item.date)
      },
      yaxis: {
        title: {
          text: 'Number of runs/errors'
        }
      },
      colors: ['#4CAF50', '#FF5252'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth'
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.8,
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left'
      },
      tooltip: {
        shared: true,
        intersect: false,
      }
    }

    new ApexCharts(this.runsAndErrorsPerDayChartTarget, options).render()
  }

  renderTasksExecutionTimeOnPeriodChart(data) {
    const uniqueDates = [...new Set(data.map(item => item.date))].sort()
    const tasks = [...new Set(data.map(item => item.task_description.slice(0, 20) + "..."))]

    const series = tasks.map(task => ({
      name: task,
      data: uniqueDates.map(date => {
        const entries = data.filter(item => item.date === date && item.task_description.slice(0, 20) + "..." === task)
        if (entries.length === 0) return 0
        const sum = entries.reduce((acc, entry) => acc + entry.execution_time, 0)
        return Math.round(sum / entries.length)
      })
    }))

    const options = {
      chart: {
        animations: {
          enabled: false,
          speed: 50,
        },
        type: 'bar',
        height: 500,
        stacked: true,
      },
      series: series,
      xaxis: {
        categories: uniqueDates,
      },
      yaxis: {
        title: {
          text: 'Execution Time (s)'
        }
      },
      colors: ['#C94C3C'],
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 0,
          barHeight: '50%',
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + " s"
        }
      },
      legend: {
        show: false,
      },
      stroke: {
        width: 2,
        colors: ['#fff']
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " s"
          }
        }
      },
    }

    new ApexCharts(this.tasksExecutionTimeOnPeriodChartTarget, options).render()
  }

  formatListData(data, color) {
    return Object.entries(data)
      .map(([key, value]) => {
        const safeKey = this.escapeHTML(key);
        const safeValue = this.escapeHTML(value.toString());
        const truncatedKey = this.truncateText(safeKey, 40);
        const safeDashKey = safeKey.replace(/\s+/g, '-');
        return `
          <div class="flex justify-between items-center p-1 rounded">
            <span class="font-medium truncate cursor-help text-sm"
                  data-tooltip-target="tooltip-${safeDashKey}"
                  data-tooltip-placement="top">
              ${truncatedKey}
            </span>
            <span class="bg-${color}-200 text-${color}-800 py-0.5 px-1.5 rounded-full text-xs">${safeValue}</span>
          </div>
          <div id="tooltip-${safeDashKey}" role="tooltip" class="absolute z-10 invisible inline-block px-2 py-1 text-xs font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
            ${safeKey}
            <div class="tooltip-arrow" data-popper-arrow></div>
          </div>
        `;
      })
      .join('');
  }

  escapeHTML(unsafeText) {
    return unsafeText
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }

  truncateText(text, maxLength) {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  }

  renderExecutionTimeChart(data) {
    const options = {
      chart: {
        animations: {
          enabled: false,
          easing: 'linear',
          speed: 50,
        },
        type: 'bar',
        height: 200,
        toolbar: {
          show: false
        },
      },
      series: [{
        name: 'Execution Time (s)',
        data: Object.values(data)
      }],
      xaxis: {
        categories: Object.keys(data)
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return Math.round(value)
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#EB6658'],
      plotOptions: {
        bar: {
          borderRadius: 4,
          columnWidth: '70%',
        }
      }
    }

    new ApexCharts(this.avgExecutionTimeTarget, options).render()
  }

  getUpArrowSVG() {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline ml-1" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
      </svg>
    `;
  }

  getDownArrowSVG() {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline ml-1" viewBox="0 0 20 20" fill="red">
        <path fill-rule="evenodd" d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z" clip-rule="evenodd" />
      </svg>
    `
  }

  formatPercentageChange(value) {
    if (typeof value === 'string') {
      value = parseFloat(value)
    }
    const isPositive = value >= 0
    const colorClass = isPositive ? 'text-green-500' : 'text-red-500'
    const arrowSVG = isPositive ? this.getUpArrowSVG() : this.getDownArrowSVG()
    const formattedValue = Math.abs(value).toLocaleString() + '%'

    return `
      <span class="${colorClass}">
        ${formattedValue}
        ${arrowSVG}
      </span>
    `
  }
}
