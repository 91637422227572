import React, { memo } from 'react';
import { Handle, Position } from '@xyflow/react';

const UICrewTaskNode = memo(({ data, isConnectable }) => {
  // Parse data.name to be more human-readable
  const parseName = (name) => {
    return name
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <div className={`react-flow__node-taskNode gradient ${data.async_execution ? 'async-execution' : ''}`} onClick={() => data.onClick(data)}>
      <Handle
        type="target"
        position={Position.Top}
        id={data.name} // Use the task name as the handle ID
        style={{
          background: '#555',
          width: '30px',
          height: '30px',
          top: '-15px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: -1,
          borderRadius: '25%',
        }}
        isConnectable={isConnectable}
      />
      <div className="wrapper">
        <div className="inner">
          <div className="task-content">
            <h4 className='m-0'>{parseName(data.name)}</h4>
            {data.async_execution && <span className="async-badge">Async</span>}
            <div className="mb-2">
              {data.description}
            </div>
            <div className="mb-2">
              <b>Agent:</b> {data.agent}
            </div>
          </div>
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id={data.name} // Use the task name as the handle ID
        style={{
          background: '#555',
          width: '30px',
          height: '30px',
          bottom: '-15px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: -1,
          borderRadius: '25%',
        }}
        isConnectable={isConnectable}
      />
    </div>
  );
});

export default UICrewTaskNode;