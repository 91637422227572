import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["totalCompleted", "totalExecutions", "errorRate", "successRate"]
  static values = { deploymentId: Number }

  connect() {
    this.fetchStats()
  }

  fetchStats() {
    fetch(`/crewai_plus/deployments/${this.deploymentIdValue}/crew_executions_tasks_data/stats`)
      .then(response => response.json())
      .then(data => {
        this.totalCompletedTarget.innerHTML = data.total_completed
        this.totalExecutionsTarget.innerHTML = data.total_executions
        this.successRateTarget.innerHTML = data.success_rate ? `${parseFloat(data.success_rate * 100).toFixed(2)}%` : "0%"
        this.errorRateTarget.innerHTML = data.error_rate ? `${parseFloat(data.error_rate * 100).toFixed(2)}%` : "0%"
      })
  }
}
