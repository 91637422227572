import { Controller } from "@hotwired/stimulus";
import { Modal } from "flowbite";

export default class extends Controller {
  static targets = [
    "form",
    "errorContainer",
    "part1",
    "part2",
    "nextButton",
    "submitButton",
  ];

  connect() {
    this.modal = new Modal(document.getElementById("user-profile-modal"), {
      backdrop: "static",
      keyboard: false,
    });
    this.validateFields();
  }

  validateFields() {
    const visiblePart = this.part1Target.classList.contains("hidden")
      ? this.part2Target
      : this.part1Target;

    const requiredFields = visiblePart.querySelectorAll(
      "input[required], select[required]"
    );
    const checkboxes = visiblePart.querySelectorAll(
      'input[type="checkbox"]:checked'
    );

    const allFieldsFilled = Array.from(requiredFields).every(
      (field) => field.value.trim() !== ""
    );
    const checkboxesSelected = checkboxes.length > 0;

    const allValid = allFieldsFilled && checkboxesSelected;

    if (this.part1Target.classList.contains("hidden")) {
      this.submitButtonTarget.disabled = !allValid;
    } else {
      this.nextButtonTarget.disabled = !allValid;
    }
  }

  nextPart() {
    this.part1Target.classList.add("hidden");
    this.part2Target.classList.remove("hidden");
    this.nextButtonTarget.classList.add("hidden");
    this.submitButtonTarget.classList.remove("hidden");
    document
      .querySelector('[data-action="click->user-profile#previousPart"]')
      .classList.remove("hidden");
    this.validateFields();
  }

  previousPart() {
    this.part2Target.classList.add("hidden");
    this.part1Target.classList.remove("hidden");
    this.submitButtonTarget.classList.add("hidden");
    this.nextButtonTarget.classList.remove("hidden");
    document
      .querySelector('[data-action="click->user-profile#previousPart"]')
      .classList.add("hidden");
    this.validateFields();
  }

  async submit(event) {
    event.preventDefault();

    if (this.submitButtonTarget.disabled) return;

    const button = this.submitButtonTarget;
    const originalText = button.textContent;

    try {
      button.disabled = true;
      button.textContent = 'Sending...';

      const form = event.target;
      const formData = new FormData(form);

      const response = await fetch(form.action, {
        method: form.method,
        body: formData,
        headers: {
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
      });

      const data = await response.json();

      if (data.success) {
        this.modal.hide();
        window.location.reload();
      } else {
        this.showErrors(data.errors);
      }
    } finally {
      button.disabled = false;
      button.textContent = originalText;
    }
  }

  showErrors(errors) {
    if (this.hasErrorContainerTarget) {
      this.errorContainerTarget.innerHTML = "";
      const errorList = document.createElement("ul");
      errors.forEach((error) => {
        const li = document.createElement("li");
        li.textContent = error;
        li.classList.add("text-red-500");
        errorList.appendChild(li);
      });
      this.errorContainerTarget.appendChild(errorList);
    } else {
      console.error("Error container not found");
    }
  }
}
