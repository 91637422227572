import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", "modalContent", "form", "scheduleList", "intervalField", "dayOfWeekField", "intervalUnit", "recurrenceType"]

  openScheduleModal(event) {
    event.preventDefault()
    const schedulableId = event.currentTarget.dataset.schedulableId
    const schedulableType = event.currentTarget.dataset.schedulableType
    fetch(`/crewai_plus/schedules/new?schedulable_id=${schedulableId}&schedulable_type=${schedulableType}`, {
      headers: {
        Accept: "text/html",
        "X-Requested-With": "XMLHttpRequest"
      }
    })
      .then(response => response.text())
      .then(html => {
        this.modalContentTarget.innerHTML = html
        this.modalTarget.classList.remove("hidden")
      })
  }

  closeModal() {
    this.modalTarget.classList.add("hidden")
    this.modalContentTarget.innerHTML = ""
  }

  submitScheduleForm(event) {
    event.preventDefault()
    const form = event.target
    const formData = new FormData(form)

    // Convert day_of_week to an array (as backend needs this way)
    const dayOfWeek = formData.get('schedule[day_of_week]')
    if (dayOfWeek) {
      formData.delete('schedule[day_of_week]')
      formData.append('schedule[day_of_week][]', dayOfWeek)
    }

    fetch(form.action, {
      method: form.method,
      body: formData,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json'
      },
      credentials: 'same-origin'
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        this.closeModal()
        this.element.dispatchEvent(new CustomEvent('schedule:created', { bubbles: true, detail: data.schedule }))
      } else {
        console.error("Error creating schedule:", data.errors)
        this.showErrors(data.errors)
      }
    })
    .catch(error => {
      console.error("Error submitting form:", error)
    })
  }

  showErrors(errors) {
    this.element.querySelectorAll('.error-message').forEach(el => el.remove());
    this.element.querySelectorAll('.border-red-500').forEach(el => el.classList.remove('border-red-500'));

    Object.entries(errors).forEach(([field, messages]) => {
      const inputElement = this.element.querySelector(`[name="schedule[${field}]"]`);
      if (inputElement) {
        const errorElement = document.createElement('p');
        errorElement.className = 'error-message text-red-500 text-sm mt-1';
        errorElement.textContent = Array.isArray(messages) ? messages.join(', ') : messages;
        inputElement.parentNode.insertBefore(errorElement, inputElement.nextSibling);
        inputElement.classList.add('border-red-500');
      } else if (field === 'base') {
        const generalErrorContainer = this.element.querySelector('.general-error-messages');
        if (generalErrorContainer) {
          generalErrorContainer.innerHTML = Array.isArray(messages) ? messages.join('<br>') : messages;
          generalErrorContainer.classList.remove('hidden');
        }
      }
    });
  }

  toggleStatus(event) {
    const checkbox = event.currentTarget
    const url = checkbox.dataset.url

    fetch(url, {
      method: 'PATCH',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      credentials: 'same-origin'
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        this.element.dispatchEvent(new CustomEvent('schedule:statusToggled', {
          bubbles: true,
          detail: { scheduleId: data.schedule.id, newStatus: data.schedule.status }
        }))
      } else {
        console.error("Error toggling schedule status:", data.errors)
        // Revert the checkbox state if there was an error
        checkbox.checked = !checkbox.checked
      }
    })
    .catch(error => {
      console.error("Error toggling status:", error)
      // Revert the checkbox state if there was an error
      checkbox.checked = !checkbox.checked
    })
  }

  deleteSchedule(event) {
    event.preventDefault()
    if (!confirm("Are you sure you want to delete this schedule?")) return

    const button = event.currentTarget
    const url = button.dataset.url

    console.log(url)

    fetch(url, {
      method: 'DELETE',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      credentials: 'same-origin'
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        this.element.dispatchEvent(new CustomEvent('schedule:deleted', {
          bubbles: true,
          detail: { scheduleId: data.scheduleId }
        }))
        this.removeScheduleRow(data.scheduleId)
      } else {
        console.error("Error deleting schedule:", data.errors)
      }
    })
    .catch(error => {
      console.error("Error deleting schedule:", error)
    })
  }

  updateScheduleRow(schedule) {
    const row = this.scheduleListTarget.querySelector(`tr[data-schedule-id="${schedule.id}"]`)
    if (row) {
      row.querySelector('.schedule-status').textContent = schedule.status
      const toggleButton = row.querySelector('.toggle-status-button')
      toggleButton.textContent = schedule.status === 'enabled' ? 'Disable' : 'Enable'
    }
  }

  removeScheduleRow(scheduleId) {
    const row = this.scheduleListTarget.querySelector(`div[data-schedule-id="${scheduleId}"]`)
    if (row) {
      row.remove()
    }
  }

  connect() {
    if (this.hasRecurrenceTypeTarget) {
      this.toggleFields()
    }
  }

  toggleFields() {
    const recurrenceType = this.recurrenceTypeTarget.querySelector('input[name="schedule[recurrence_type]"]:checked')
    if (!recurrenceType) return

    const recurrenceValue = recurrenceType.value

    this.recurrenceTypeTarget.querySelectorAll('input[name="schedule[recurrence_type]"]').forEach(input => {
      const label = input.nextElementSibling
      if (input.checked) {
        label.classList.add('bg-indigo-600', 'text-white')
        label.classList.remove('bg-white', 'text-gray-700')
      } else {
        label.classList.remove('bg-indigo-600', 'text-white')
        label.classList.add('bg-white', 'text-gray-700')
      }
    })

    if (this.hasIntervalFieldTarget) {
      const intervalInputs = this.intervalFieldTarget.querySelectorAll('input, select');
      intervalInputs.forEach(input => {
        input.disabled = recurrenceValue === 'once';
      });
    }

    if (this.hasDayOfWeekFieldTarget) {
      const dayOfWeekInput = this.dayOfWeekFieldTarget.querySelector('select');
      if (dayOfWeekInput) {
        dayOfWeekInput.disabled = recurrenceValue !== 'weekly';
      }
    }

    if (this.hasIntervalUnitTarget) {
      this.intervalUnitTarget.textContent = this.getIntervalUnit(recurrenceValue)
    }
  }

  getIntervalUnit(recurrenceType) {
    switch (recurrenceType) {
      case 'daily': return 'day(s)'
      case 'weekly': return 'week(s)'
      case 'monthly': return 'month(s)'
      default: return ''
    }
  }

  applyPreset(event) {
    const preset = event.currentTarget.dataset.preset
    const form = this.element

    switch (preset) {
      case 'daily':
        form.querySelector('input[value="daily"]').checked = true
        form.querySelector('#schedule_recurrence_interval').value = 1
        form.querySelector('#schedule_time_of_day').value = '00:00'
        break
      case 'weekly':
        form.querySelector('input[value="weekly"]').checked = true
        form.querySelector('#schedule_recurrence_interval').value = 1
        form.querySelector('#schedule_day_of_week').value = 1 // Monday
        form.querySelector('#schedule_time_of_day').value = '00:00'
        break
    }

    this.toggleFields()
  }
}
