import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["timeframe", "period", "customStartDate", "customEndDate", "distribution", "filterWrapper", "aggregation", "customStartDateWrapper", "customEndDateWrapper"]

  connect() {
    this.updateCustomDateVisibility()
  }

  async handleFilterChange(event) {
    if (event.target === this.periodTarget) {
      this.updateCustomDateVisibility()
    }

    const params = this.getFilterParams()

    // Dispatch custom event to update both charts
    const customEvent = new CustomEvent("filter-changed", {
      detail: params,
      bubbles: true
    })
    this.filterWrapperTarget.dispatchEvent(customEvent)
  }

  updateCustomDateVisibility() {
    if (this.periodTarget.value === 'custom') {
      this.customStartDateWrapperTarget.classList.remove('hidden')
      this.customEndDateWrapperTarget.classList.remove('hidden')
    } else {
      this.customStartDateWrapperTarget.classList.add('hidden')
      this.customEndDateWrapperTarget.classList.add('hidden')
    }
  }

  getFilterParams() {
    const params = {
      timeframe: this.timeframeTarget.value,
      period: this.periodTarget.value,
      distribution: this.distributionTarget.value,
      aggregation: this.aggregationTarget.value
    }

    if (this.periodTarget.value === 'custom') {
      params.start_date = this.customStartDateTarget.value
      params.end_date = this.customEndDateTarget.value
    }

    return params
  }
}
