// app/javascript/controllers/deployment_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { id: Number, status: String, url: String, token: String };
  connect() {
    const redeployButton = this.element.querySelector(`.redeploy-button-${this.idValue}`);

    if (this.statusValue.toLowerCase() !== 'crew is online') {
      this.checkStatus()
    } else {
      redeployButton.style.display = 'flex';
    }
  }

  checkStatus() {
    const url = `/crewai_plus/deployments/${this.idValue}/check_provision_status`;
    fetch(url, {
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Accept': 'application/json'
      },
      credentials: 'same-origin'
    })
    .then(response => response.json())
    .then(data => {
      const statusElement = this.element.querySelector(`.deployment-status-${this.idValue}`);
      const progressElement = this.element.querySelector(`.deployment-progress-${this.idValue}`);
      const spinnerElement = this.element.querySelector(`.deployment-spinner-${this.idValue}`);
      const progressHolderElement = this.element.querySelector(`.deployment-progress-holder-${this.idValue}`);
      const urlElement = this.element.querySelector(`.deployment-public-url-${this.idValue}`);
      const deploymentOverallProgress = this.element.querySelector(`.deployment-overall-progress-${this.idValue}`);
      const redeployButton = this.element.querySelector(`.redeploy-button-${this.idValue}`);

      if (data.progress == 0) {
        spinnerElement.style.display = 'none';
        statusElement.classList.add('text-red-300')
      }

      if (data.progress == 100) {
        deploymentOverallProgress.style.display = 'none';
        progressHolderElement.style.display = 'none';
        redeployButton.style.display = 'flex';

        if (data.public_url) {
          this.urlValue = data.public_url;
          const public_url = data.public_url.substring(0, 35);
          urlElement.textContent = public_url
        }
      } else {
        progressElement.style.width = `${data.progress}%`;
      statusElement.textContent = data.status.charAt(0).toUpperCase() + data.status.slice(1);
        setTimeout(() => this.checkStatus(), 15000); // Retry in 5 seconds
      }
    })
    .catch(error => console.error('Error:', error));
  }

  copyUrl(event) {
    event.preventDefault();
    if (!navigator.clipboard) {
      console.error("Clipboard API not available.");
      return;
    }

    navigator.clipboard.writeText(this.urlValue)
      .then(() => {
        const popup = this.element.querySelector(`#copy-confirmation-${this.idValue}`);
        if (popup) {
          // Show tooltip
          popup.classList.remove('opacity-0', 'translate-y-2');
          popup.classList.add('opacity-100', 'translate-y-0');

          // Hide tooltip after 2 seconds
          setTimeout(() => {
            popup.classList.remove('opacity-100', 'translate-y-0');
            popup.classList.add('opacity-0', 'translate-y-2');
          }, 1500);
        }
      })
      .catch(err => {
        console.error('Failed to copy URL: ', err);
      });
  }

  copyToken(event) {
    event.preventDefault();
    if (!navigator.clipboard) {
      console.error("Clipboard API not available.");
      return;
    }

    navigator.clipboard.writeText(this.tokenValue)
      .then(() => {
        const popup = this.element.querySelector(`#copy-token-confirmation-${this.idValue}`);
        if (popup) {
          // Show tooltip
          popup.classList.remove('opacity-0', 'translate-y-2');
          popup.classList.add('opacity-100', 'translate-y-0');

          // Hide tooltip after 2 seconds
          setTimeout(() => {
            popup.classList.remove('opacity-100', 'translate-y-0');
            popup.classList.add('opacity-0', 'translate-y-2');
          }, 1500);
        }
      })
      .catch(err => {
        console.error('Failed to copy URL: ', err);
      });
  }
}
