import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';

const UICrewTaskModal = ({
  task,
  onClose,
  onAgentChange,
  onToolsChange,
  onDescriptionChange,
  onExpectedOutputChange,
  availableAgents,
  availableTools,
  crewId
}) => {
  const [localTask, setLocalTask] = useState(task);
  const [fieldsChanged, setFieldsChanged] = useState(false);

  useEffect(() => {
    setLocalTask(task);
  }, [task]);

  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;
    setLocalTask(prev => ({ ...prev, description: newDescription }));
    setFieldsChanged(true);
    onDescriptionChange(task.id, newDescription);
  };

  const handleExpectedOutputChange = (e) => {
    const newExpectedOutput = e.target.value;
    setLocalTask(prev => ({ ...prev, expected_output: newExpectedOutput }));
    setFieldsChanged(true);
    onExpectedOutputChange(task.id, newExpectedOutput);
  };

  const handleAgentChange = (e) => {
    const newAgent = e.target.value;
    setLocalTask(prev => ({ ...prev, agent: newAgent }));
    setFieldsChanged(true);
    onAgentChange(task.id, newAgent);
  };

  const handleToolsChange = (selectedOptions) => {
    const selectedTools = selectedOptions.map(option => option.value);
    setLocalTask(prev => ({ ...prev, tools: selectedTools }));
    setFieldsChanged(true);
    onToolsChange(task.id, selectedTools);
  };

  const handleClose = useCallback(async () => {
    if (!fieldsChanged) {
      onClose();
      return;
    }

    try {
      const response = await fetch(`/crewai_plus/ui_studio/ui_crews/${crewId}/update_task`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({
          task: {
            id: localTask.id,
            name: localTask.name,
            description: localTask.description,
            expected_output: localTask.expected_output,
            agent: localTask.agent,
            tools: localTask.tools,
            context: localTask.context || []
          }
        })
      });

      if (!response.ok) {
        throw new Error('Failed to update task');
      }

      onClose();
    } catch (error) {
      console.error('Error updating task:', error);
    }
  }, [fieldsChanged, localTask, onClose, crewId]);

  return (
    <div className="task-modal-overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center" onClick={handleClose}>
      <div className="task-modal-content bg-white rounded-lg p-8 max-w-md w-full shadow-xl" onClick={(e) => e.stopPropagation()}>
        <h2 className="text-2xl font-bold m-0 mb-2">Task Details</h2>
        <div>
          <div className="mb-1">
            <span className="font-semibold text-sm">Description:</span>
            <textarea
              className="text-xs block w-full mt-1 p-2 border rounded focus:border-primary-color focus:ring focus:ring-primary-color-light focus:ring-opacity-50"
              value={localTask.description}
              onChange={handleDescriptionChange}
            />
          </div>
          <div className="mb-1">
            <span className="font-semibold text-sm">Expected Output:</span>
            <textarea
              className="text-xs block w-full mt-1 p-2 border rounded focus:border-primary-color focus:ring focus:ring-primary-color-light focus:ring-opacity-50"
              value={localTask.expected_output}
              onChange={handleExpectedOutputChange}
            />
          </div>
          <div className="mb-1">
            <span className="font-semibold text-sm">Agent:</span>
            <select
              className="text-xs block w-full mt-1 p-2 border rounded focus:border-primary-color focus:ring focus:ring-primary-color-light focus:ring-opacity-50"
              value={localTask.agent}
              onChange={handleAgentChange}
            >
              {availableAgents.map((agent) => (
                <option key={agent.role} value={agent.role}>
                  {agent.role}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-1">
            <span className="font-semibold text-sm">Tools:</span>
            <Select
              isMulti
              options={availableTools.map(tool => ({ value: tool, label: tool }))}
              value={localTask.tools.map(tool => ({ value: tool, label: tool }))}
              onChange={handleToolsChange}
              className="text-xs mt-1"
              classNamePrefix="react-select"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UICrewTaskModal;
