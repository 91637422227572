import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toolsCallsPerRun", "errorsPerRun", "delegationsPerRun", "cacheHitRate"]
  static values = { deploymentId: Number }

  connect() {
    this.fetchStats()
  }

  fetchStats() {
    fetch(`/crewai_plus/deployments/${this.deploymentIdValue}/tools_usage/stats`)
      .then(response => response.json())
      .then(data => {
        this.toolsCallsPerRunTarget.innerHTML = data.calls_per_run ? parseFloat(data.calls_per_run).toFixed(2) : "0"
        this.errorsPerRunTarget.innerHTML = data.errors_per_run ? parseFloat(data.errors_per_run).toFixed(2) : "0"
        this.delegationsPerRunTarget.innerHTML = data.delegations_per_run ? parseFloat(data.delegations_per_run).toFixed(2) : "0"
        this.cacheHitRateTarget.innerHTML = data.cache_hit_rate ? `${parseFloat(data.cache_hit_rate).toFixed(2)}%` : "0%"
      })
  }
}
