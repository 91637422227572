import React, { useState } from 'react';

const AddCardForm = ({ onAddCard, onCancel, availableInputs, deployment }) => {
  const [inputs, setInputs] = useState({});
  const [generateArtifact, setGenerateArtifact] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = (e) => {
    e.preventDefault();
    onCancel();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLoading) return; // Prevent multiple submissions
    setIsLoading(true);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', `/crewai_plus/management_ui/${deployment.id}/kickoff`, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content);

    try {
      xhr.send(JSON.stringify({ 
        inputs: inputs,
        generateArtifact: generateArtifact 
      }));

      if (xhr.status !== 200) {
        throw new Error(`HTTP error! status: ${xhr.status}`);
      }

      JSON.parse(xhr.responseText); // Parse the response, but we're not using it
      onAddCard(inputs);
    } catch (error) {
      console.error('Error kicking off crew:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-2">
      <div className="bg-white rounded-lg shadow-md p-4">
        <h2 className="text-sm m-0 mb-4 text-gray-800">Trigger new Crew</h2>
        <div className="space-y-4">
          {availableInputs.map((input, index) => (
            <div key={index} className="flex flex-col">
              <label htmlFor={input} className="mb-1 font-medium text-gray-700 text-xs">
                {input.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
              </label>
              <input
                type="text"
                id={input}
                name={input}
                value={inputs[input] || ''}
                onChange={(e) => setInputs({ ...inputs, [input]: e.target.value })}
                className="text-xs border border-gray-200 rounded-md px-3 py-1 focus:outline-none focus:ring-2 focus:ring-primary-color focus:border-primary-color"
                placeholder={`Enter ${input.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}`}
                required
              />
            </div>
          ))}
          
          <div className="flex items-center">
            <input
              type="checkbox"
              id="generateArtifact"
              checked={generateArtifact}
              onChange={(e) => setGenerateArtifact(e.target.checked)}
              className="h-4 w-4 text-primary-color border-gray-300 rounded focus:ring-primary-color"
            />
            <label htmlFor="generateArtifact" className="ml-2 text-xs text-gray-700">
              Generate Artifact
            </label>
            <span class="ml-1 text-gray-500 relative group">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4 cursor-help"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                />
              </svg>
              <span
                class="
                  tooltip invisible group-hover:visible opacity-0 group-hover:opacity-100
                  transition-opacity duration-300 absolute z-10 p-2 -mt-2 text-xs leading-tight
                  text-white bg-gray-700 rounded-lg shadow-lg ml-2 w-48
                "
              >
                By enabling artifact generation, token usage will increase as an additional evaluation step is performed to analyze the crew's output.
              </span>
            </span>
          </div>
        </div>
        <div className="mt-4 flex justify-end space-x-2">
          <button
            onClick={handleCancel}
            className="w-1/2 bg-gray-300 hover:bg-gray-400 text-gray-800 py-1 px-3 rounded text-sm transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            disabled={isLoading}
            className="w-1/2 bg-primary-color hover:bg-primary-color-dark text-white py-1 px-3 rounded text-sm transition-colors duration-200"
          >
            {isLoading ? 'Creating...' : 'Trigger Crew'}
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddCardForm;
