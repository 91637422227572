import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "button"]

  toggle() {
    this.contentTarget.classList.toggle("hidden")
    this.buttonTarget.textContent = this.contentTarget.classList.contains("hidden")
      ? "Show tools usage"
      : "Hide tools usage"
  }
}
