import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="accordion"
export default class extends Controller {
  static targets = ["content", "icon"];

  toggle() {
    this.contentTargets.forEach(content => content.classList.toggle("hidden"))
    this.iconTargets.forEach(icon => icon.classList.toggle("rotate-180"))
  }
}
