import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "status", "toggle", 'pill', 'button']


  static classes = {
    active: 'bg-primary-color',
    inactive: 'bg-gray-200',
    pill: 'w-11 h-6 rounded-full transition-colors duration-200 ease-in-out',
    toggle: 'absolute left-0.5 top-0.5 w-5 h-5 bg-white rounded-full transition-transform duration-200 ease-in-out transform'
  }

  connect() {
    this.initial_toggle()
  }

  toggle() {
    let isChecked = this.checkboxTarget.checked
    if (isChecked) {
      this.checkboxTarget.checked = false
      this.statusTarget.textContent = 'Disabled'
    } else {
      this.checkboxTarget.checked = true
      this.statusTarget.textContent = 'Enabled'
    }
    this.updateToggleState()
  }

  updateToggleState() {
    let isChecked = this.checkboxTarget.checked
    const pillElement = this.pillTarget
    const toggleElement = this.buttonTarget

    if (isChecked) {
      pillElement.classList.remove('bg-gray-200')
      pillElement.classList.add('bg-primary-color') // Set background to primary-color
      toggleElement.style.transform = 'translateX(100%)'

    } else {
      pillElement.classList.remove('bg-primary-color')
      pillElement.classList.add('bg-gray-200') // Reset background color
      toggleElement.style.transform = 'translateX(0)'
    }
  }
  initial_toggle() {
    let isChecked = this.checkboxTarget.checked
    const pillElement = this.pillTarget
    const toggleElement = this.buttonTarget

    if (isChecked) {
      pillElement.classList.remove('bg-gray-200')
      pillElement.classList.add('bg-primary-color') // Set background to primary-color
      toggleElement.style.transform = 'translateX(100%)'

    } else {
      pillElement.classList.remove('bg-primary-color')
      pillElement.classList.add('bg-gray-200') // Reset background color
      toggleElement.style.transform = 'translateX(0)'
    }
  }
}
