import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="deployment-show"
export default class extends Controller {
  static values = { id: Number, url: String, token: String };
  static targets = [
    "tab", "content", "trainResponse", "testResponse", "editButton", "viewMode", "editMode", "editArea",
    "saveButton", "saveButtonText", "saveSpinner", "modelCheckbox", "nIterations"
  ];

  connect() {
    this.boundOnPopState = this.onPopState.bind(this)
    window.addEventListener('popstate', this.boundOnPopState)
    this.pollInterval = null

    // Only call showTabFromAnchor on initial page load, not on turbo navigation
    if (!window.performance.getEntriesByType("navigation")[0].type.includes("back_forward")) {
      this.showTabFromAnchor()
    }
  }

  disconnect() {
    this.stopPolling()
    window.removeEventListener('popstate', this.boundOnPopState)
  }

  onPopState(event) {
    if(event.state && event.state.tabHref) {
      event.preventDefault()
      this.showTab(event.state.tabHref)
    }
  }

  showTabFromAnchor() {
    const anchor = window.location.hash.slice(1)
    if (anchor) {
      const tab = this.tabTargets.find(t => t.getAttribute('href') === `#${anchor}`)
      if (tab) {
        this.showTab(`#${anchor}`)
      }
    }
  }

  trainCrew(event) {
    event.preventDefault();
    const trainButton = event.currentTarget;

    // Collect input values
    const inputs = {};
    let hasError = false;
    document.querySelectorAll('#training-inputs input').forEach(input => {
      if (!input.value.trim()) {
        this.showErrorMessage(input, `${input.name} is required`);
        hasError = true;
      } else {
        inputs[input.name] = input.value.trim();
        this.clearErrorMessage(input);
      }
    });

    if (hasError) {
      return; // Don't proceed if there are errors
    }

    // Immediately update button appearance
    this.updateTrainButton(trainButton, true);

    const url = `/crewai_plus/deployments/${this.idValue}/train_crew`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content")
      },
      body: JSON.stringify({ inputs })
    })
      .then(response => response.json())
      .then(data => {
        if (data.kickoff_id) {
          // Refresh the page if kickoff_id is returned
          window.location.reload();
        } else {
          this.updateTrainButton(trainButton, false);
          this.showErrorMessage(trainButton, `Error: ${data.error || 'Unknown data error'}`);
        }
      })
      .catch(error => {
        this.updateTrainButton(trainButton, false);
        this.showErrorMessage(trainButton, `Error: ${error.message || 'Unknown error'}`);
      });
  }

  updateTrainButton(button, isLoading) {
    button.disabled = isLoading;
    button.textContent = isLoading ? 'Training...' : 'Train Crew';
    button.classList.toggle('bg-gray-400', isLoading);
    button.classList.toggle('cursor-not-allowed', isLoading);
    button.classList.toggle('bg-primary-color', !isLoading);
    button.classList.toggle('hover:bg-primary-color', !isLoading);
  }

  showErrorMessage(element, errorText) {
    this.clearErrorMessage(element);
    const message = document.createElement('span');
    message.textContent = errorText;
    message.classList.add('ml-2', 'text-primary-color', 'error-message');
    element.parentNode.insertBefore(message, element.nextSibling);
  }

  clearErrorMessage(element) {
    const errorMessage = element.parentNode.querySelector('.error-message');
    if (errorMessage) {
      errorMessage.remove();
    }
  }

  show(event) {  // from click->show
    event.preventDefault();
    const href = event.target.getAttribute("href");
    this.showTab(href);

    history.pushState({ turbo: {}, tabHref: href }, '', href)
  }

  showTab(selectedTabId) {
    this.tabTargets.forEach((tab) => {
      const tabId = tab.getAttribute("href")
      const content = document.querySelector(tabId)
      if (tabId === selectedTabId) {
        tab.classList.add('active-tab', 'text-primary-color', 'border-primary-color')
        content.classList.remove("hidden")
      } else {
        tab.classList.remove('active-tab', 'text-primary-color', 'border-primary-color')
        content.classList.add("hidden")
      }
    })
  }

  copyUrl(event) {
    event.preventDefault();
    if (!navigator.clipboard) {
      console.error("Clipboard API not available.");
      return;
    }

    navigator.clipboard.writeText(this.urlValue)
      .then(() => {
        const popup = this.element.querySelector(`#copy-confirmation-${this.idValue}`);
        if (popup) {
          // Show tooltip
          popup.classList.remove('opacity-0', 'translate-y-2');
          popup.classList.add('opacity-100', 'translate-y-0');

          // Hide tooltip after 2 seconds
          setTimeout(() => {
            popup.classList.remove('opacity-100', 'translate-y-0');
            popup.classList.add('opacity-0', 'translate-y-2');
          }, 1500);
        }
      })
      .catch(err => {
        console.error('Failed to copy URL: ', err);
      });
  }

  copyToken(event) {
    event.preventDefault();
    if (!navigator.clipboard) {
      console.error("Clipboard API not available.");
      return;
    }

    navigator.clipboard.writeText(this.tokenValue)
      .then(() => {
        const popup = this.element.querySelector(`#copy-token-confirmation-${this.idValue}`);
        if (popup) {
          // Show tooltip
          popup.classList.remove('opacity-0', 'translate-y-2');
          popup.classList.add('opacity-100', 'translate-y-0');

          // Hide tooltip after 2 seconds
          setTimeout(() => {
            popup.classList.remove('opacity-100', 'translate-y-0');
            popup.classList.add('opacity-0', 'translate-y-2');
          }, 1500);
        }
      })
      .catch(err => {
        console.error('Failed to copy URL: ', err);
      });
  }

  async testCrew(event) {
    event.preventDefault();
    const deploymentId = this.idValue;

    const selectedModels = this.modelCheckboxTargets
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value);

    const nIterations = parseInt(this.nIterationsTarget.value, 10);
    const inputs = {};
    document.querySelectorAll('#test-inputs input').forEach(input => {
      if (!input.value.trim()) {
        this.showErrorMessage(input, `${input.name} is required`);
        hasError = true;
      } else {
        inputs[input.name] = input.value.trim();
        this.clearErrorMessage(input);
      }
    });

    if (selectedModels.length === 0) {
      this.testResponseTarget.textContent = 'Please select at least one model for testing.';
      return;
    }

    if (isNaN(nIterations) || nIterations < 1) {
      this.testResponseTarget.textContent = 'Please enter a valid number of iterations (minimum 1).';
      return;
    }

    const response = await fetch(`/crewai_plus/deployments/${deploymentId}/test_crew`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        n_iterations: nIterations,
        models: selectedModels,
        inputs: inputs
      })
    });

    if (response.ok) {
      this.updateTestButton('started');
      this.startPolling();
      }
    else {
      this.testResponseTarget.textContent = 'Failed to start the test.';
    }
  }

  toggleEdit(event) {
    const roleId = event.currentTarget.closest('[data-role-id]').dataset.roleId;
    this.viewModeTargets.find(t => t.dataset.roleId === roleId).classList.toggle("hidden");
    this.editModeTargets.find(t => t.dataset.roleId === roleId).classList.toggle("hidden");
    this.editButtonTargets.find(t => t.dataset.roleId === roleId).classList.toggle("hidden");
  }

  cancelEdit(event) {
    const roleElement = event.currentTarget.closest('[data-role-id]');
    const roleId = roleElement.dataset.roleId;
    const viewMode = this.viewModeTargets.find(t => t.dataset.roleId === roleId);
    const editArea = this.editAreaTargets.find(t => t.dataset.roleId === roleId);

    // Reset the textarea content to the original suggestions
    const originalSuggestions = Array.from(viewMode.querySelectorAll('li')).map(li => li.textContent).join('\n');
    editArea.value = originalSuggestions;

    // Toggle back to view mode
    this.toggleEdit({ currentTarget: this.editButtonTargets.find(t => t.dataset.roleId === roleId) });
  }

  saveSuggestions(event) {
    event.preventDefault();
    const roleElement = event.currentTarget.closest('[data-role-id]');
    const roleId = roleElement.dataset.roleId;
    const suggestions = this.editAreaTargets.find(t => t.dataset.roleId === roleId).value.split("\n").filter(s => s.trim() !== "");

    // Show spinner and disable button
    this.toggleSaveButtonState(roleId, true);

    fetch(`/crewai_plus/deployments/${this.idValue}/update_suggestions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        role_id: roleId,
        suggestions: suggestions
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        this.updateSuggestionsList(roleId, suggestions);
        this.toggleEdit({ currentTarget: this.editButtonTargets.find(t => t.dataset.roleId === roleId) });
      } else {
        console.error('Failed to update suggestions');
      }
    })
    .catch(error => {
      console.error('Error:', error);
    })
    .finally(() => {
      // Hide spinner and enable button
      this.toggleSaveButtonState(roleId, false);
    });
  }

  toggleSaveButtonState(roleId, isLoading) {
    const saveButton = this.saveButtonTargets.find(t => t.closest('[data-role-id]').dataset.roleId === roleId);
    const saveButtonText = saveButton.querySelector('[data-deployment-show-target="saveButtonText"]');
    const saveSpinner = saveButton.querySelector('[data-deployment-show-target="saveSpinner"]');

    saveButton.disabled = isLoading;
    saveButtonText.textContent = isLoading ? 'Saving...' : 'Save';
    saveSpinner.classList.toggle('hidden', !isLoading);
  }

  updateSuggestionsList(roleId, suggestions) {
    const ul = this.viewModeTargets.find(t => t.dataset.roleId === roleId).querySelector('ul');
    ul.innerHTML = suggestions.map(s => `<li>${s}</li>`).join('');
  }
  startPolling() {
    this.stopPolling() // Clear any existing interval
    this.pollInterval = setInterval(() => this.pollTestStatus(), 4000)
  }

  stopPolling() {
    if (this.pollInterval) {
      clearInterval(this.pollInterval)
    }
  }

  pollTestStatus() {
    fetch(`/crewai_plus/deployments/${this.idValue}/test_status`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        // Store the current status in a data attribute
        this.element.dataset.testStatus = data.status;

        this.updateTestButton(data.status);

        if (data.status === 'started') {
          this.testResponseTarget.textContent = "";
          this.testResponseTarget.classList.remove('bg-red-100', 'border-red-400', 'text-red-700', 'px-4', 'py-3', 'rounded', 'relative');
        }

        if (data.status === 'error') {
          this.displayErrorMessage(`Error: ${data.notice} Please try again.`);
          this.stopPolling();
        }
        if (data.status === 'finished') {
          if (data.results.length === 0) {
            this.displayErrorMessage("Error: No results found. You can find the error message in executions tab.");
            this.updateTestButton('Something went wrong');
          } else {
            this.updateTestResults(data.results);
            this.updateTestButton('Finished, Refresh to see results');
          }
          this.stopPolling();
        }
      })
      .catch(error => {
        this.stopPolling();
        this.updateTestButton('error');
        this.displayErrorMessage(`Error: ${error.message}. Please try again.`);
      });
  }

  displayErrorMessage(message) {
    this.testResponseTarget.textContent = message;
    this.testResponseTarget.classList.add('bg-red-100', 'border', 'border-red-400', 'text-red-700', 'px-4', 'py-3', 'rounded', 'relative', 'my-4');
  }

  updateTestButton(status) {
    const button = document.getElementById('test-status-button');
    const modelComparisonData = document.getElementById('model-comparison-data');

    // Always hide model comparison data initially
    modelComparisonData?.classList.add('hidden');

    button.disabled = false;
    button.classList.remove('bg-gray-400', 'cursor-not-allowed', 'bg-red-500', 'hover:bg-red-600', 'bg-primary-color', 'hover:bg-primary-color', 'bg-yellow-500', 'hover:bg-yellow-600');

    switch(status) {
      case 'started':
        button.disabled = true;
        button.classList.add('bg-gray-400', 'cursor-not-allowed');
        button.innerHTML = `Crew is still testing...<svg class="animate-spin inline-block ml-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
        break;
      case 'finished':
        button.classList.add('bg-primary-color', 'hover:bg-primary-color');
        button.textContent = 'Test Finished, Refresh to see results';
        // Show the model comparison data when finished
        modelComparisonData?.classList.remove('hidden');
        break;
      case 'error':
        button.classList.add('bg-yellow-500', 'hover:bg-yellow-600');
        button.textContent = 'Retry Test';
        break;
      default:
        button.classList.add('bg-red-500', 'hover:bg-red-600');
        button.textContent = 'Test Crew';
    }
  }
  updateTestResults(results) {
    const tableContainer = document.querySelector('#model-comparison-data')
    if (!tableContainer) return

    const runCount = results[0].length

    let tableHTML = `
      <table class="min-w-full bg-white border border-gray-300 rounded-lg">
        <thead class="bg-gray-100">
          <tr>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Model</th>
            ${Array(runCount).fill().map((_, i) => `
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Run ${i + 1}</th>
            `).join('')}
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Averages</th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200">
    `

    results.forEach(modelResults => {
      const model = modelResults[0].model
      const crewAverage = modelResults[0].crew_average
      const taskAverages = modelResults[0].task_averages

      tableHTML += `
        <tr>
          <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">${model}</td>
      `

      modelResults.forEach((run, runIndex) => {
        tableHTML += `
          <td class="px-6 py-4 whitespace-nowrap">
            <div class="space-y-2">
              <div class="text-sm text-gray-900"><span class="font-semibold">Task Scores:</span>
                ${Object.entries(run.task_scores).map(([runNum, scores]) => {
                  if (parseInt(runNum) === runIndex + 1) {
                    return scores.map((score, taskIndex) => `
                      <div class="text-sm text-gray-900">
                        Task ${taskIndex + 1}: <span class="font-semibold">${score}</span>
                      </div>
                    `).join('')
                  }
                  return ''
                }).join('')}
              </div>
              <div class="text-sm text-gray-900"><span class="font-semibold">Crew Score:</span>
                <span class="font-semibold">${run.crew_scores[runIndex]}</span>
              </div>
              <div class="text-sm text-gray-500">Execution Time: <span class="font-semibold">${run.execution_time}s</span></div>
            </div>
          </td>
        `
      })

      tableHTML += `
          <td class="px-6 py-4 whitespace-nowrap text-sm font-semibold text-gray-900">
            <div class="mb-2">
              <span class="font-semibold">Task Averages:</span>
              ${taskAverages.map((avg, index) => `
                <div class="text-sm">
                  Task ${index + 1}: <span class="font-semibold text-primary-color">${avg.toFixed(2)}</span>
                </div>
              `).join('')}
            </div>
            <div>Crew Average: <span class="text-primary-color">${crewAverage}</span></div>
            <div class="text-sm">
              <span class="text-gray-500 text-sm font-normal">Avg Execution Time:</span>
              <span class="text-primary-color">
                ${(modelResults.reduce((sum, run) => sum + parseFloat(run.execution_time), 0) / modelResults.length).toFixed(2)}s
              </span>
            </div>
          </td>
        </tr>
      `

      tableHTML += `
        <tr class="bg-gray-50">
          <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-700">Total Tokens</td>
      `
      modelResults.forEach(run => {
        tableHTML += `
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
            ${this.formatLargeNumber(Math.round(run.token_usage.total_tokens))}
          </td>
        `
      })

      const avgTokens = modelResults.reduce((sum, run) => sum + run.token_usage.total_tokens, 0) / runCount

      tableHTML += `
          <td class="px-6 py-4 whitespace-nowrap text-sm font-semibold text-gray-700">
            ${this.formatLargeNumber(Math.round(avgTokens))}
          </td>
        </tr>
      `
    })

    tableHTML += `
        </tbody>
      </table>
    `

    tableContainer.innerHTML = tableHTML
    tableContainer.classList.remove('hidden')
  }

  formatLargeNumber(num) {
    if (num >= 1e9) {
      return (num / 1e9).toFixed(1) + 'B';
    }
    if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + 'M';
    }
    return num.toLocaleString();
  }
}
