import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source", "button"]

  copy() {
    const content = this.sourceTarget.textContent
    navigator.clipboard.writeText(content).then(() => {
      const originalContent = this.buttonTarget.innerHTML
      this.buttonTarget.textContent = "Copied!"
      this.buttonTarget.classList.add("bg-gray-300", "rounded", "px-2", "py-1")

      setTimeout(() => {
        this.buttonTarget.innerHTML = originalContent
        this.buttonTarget.classList.remove("bg-gray-300", "rounded", "px-2", "py-1")
      }, 500)
    }).catch(err => {
      console.error('Failed to copy: ', err)
    })
  }
}
