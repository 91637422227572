import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["planDetails", "upgradeButton"]
  
  connect() {
    this.selectedPlanId = null
    this.previousHighlightedColumn = null
  }

  show(event) {
    const { name, priceId } = event.params

    // Reset previous selection
    if (this.selectedPlanId) {
      const previousCard = document.getElementById(`plan-${this.selectedPlanId}`)
      if (previousCard) {
        previousCard.classList.remove('border-blue-500', 'border-purple-500', 'border-yellow-500')
        previousCard.classList.add('border-gray-200')
      }
    }

    // Reset previous column highlight
    if (this.previousHighlightedColumn) {
      const cells = document.querySelectorAll(`td:nth-child(${this.previousHighlightedColumn})`)
      cells.forEach(cell => {
        cell.classList.remove('bg-blue-50')
      })
      const headers = document.querySelectorAll(`th:nth-child(${this.previousHighlightedColumn})`)
      headers.forEach(header => {
        header.classList.remove('bg-blue-100')
      })
    }

    // Determine column index based on plan name
    let columnIndex
    switch(name.toLowerCase()) {
      case 'free plan': columnIndex = 2; break
      case 'basic plan': columnIndex = 3; break
      case 'standard plan': columnIndex = 4; break
      case 'pro plan': columnIndex = 5; break
      case 'enterprise plan': columnIndex = 6; break
      case 'ultra plan': columnIndex = 7; break
      default: columnIndex = null
    }

    // Remove highlighting from previous column
    if (this.previousHighlightedColumn) {
      const previousCells = document.querySelectorAll(`td:nth-child(${this.previousHighlightedColumn})`)
      previousCells.forEach(cell => {
        cell.classList.remove('bg-primary-color', 'text-white')
      })
      const previousHeaders = document.querySelectorAll(`th:nth-child(${this.previousHighlightedColumn})`)
      previousHeaders.forEach(header => {
        header.classList.remove('bg-primary-color', 'text-white')
      })
    }

    // Highlight new column if found
    if (columnIndex) {
      const cells = document.querySelectorAll(`td:nth-child(${columnIndex})`)
      cells.forEach(cell => {
        cell.classList.add('bg-primary-color', 'text-white')
      })
      const headers = document.querySelectorAll(`th:nth-child(${columnIndex})`)
      headers.forEach(header => {
        header.classList.add('bg-primary-color', 'text-white')
      })
      this.previousHighlightedColumn = columnIndex
    }

    // Highlight new selection
    const selectedCard = document.getElementById(`plan-${priceId}`)
    if (selectedCard) {
      selectedCard.classList.remove('border-gray-200')
      selectedCard.classList.add('border-primary-color')
      document.querySelectorAll('[id^="plan-"]').forEach(card => {
        if (card.id !== `plan-${priceId}`) {
          card.classList.remove('border-primary-color')
          card.classList.add('border-gray-200')
        }
      })
    }

    this.selectedPlanId = priceId
    this.planDetailsTarget.classList.remove('hidden')
    this.planDetailsTarget.scrollIntoView({ behavior: 'smooth' })
  }

  confirmUpgrade(event) {
    event.stopPropagation()
    const confirmMessage = event.currentTarget.dataset.confirm;
    if (!confirm(confirmMessage)) {
      return event.preventDefault();
    }
    
    const button = this.upgradeButtonTarget;
    button.innerHTML = "Upgrading...";
    button.disabled = true;
    const form = button.closest('form');
    if (form) {
      form.submit();
    }
  }
}
