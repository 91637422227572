import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["supportedModels", "providerModel"]

  showModels(event) {
    const provider = event.target.value
    this.supportedModelsTarget.style.display = 'block'
    
    this.providerModelTargets.forEach(el => el.style.display = 'none')
    
    const providerModels = this.providerModelTargets.find(el => el.dataset.provider === provider)
    if (providerModels) {
      providerModels.style.display = 'block'
    }
  }
}
