import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["chart"]
  static values = { tasks: Array }

  connect() {
    this.loadGoogleCharts()
    this.boundDrawChart = this.drawChart.bind(this)
    window.addEventListener('resize', this.boundDrawChart)
  }

  disconnect() {
    window.removeEventListener('resize', this.boundDrawChart)
  }

  loadGoogleCharts() {
    if (typeof google === 'undefined') {
      const script = document.createElement('script')
      script.src = 'https://www.gstatic.com/charts/loader.js'
      script.onload = () => this.onGoogleChartsLoaded()
      document.head.appendChild(script)
    } else {
      this.onGoogleChartsLoaded()
    }
  }

  onGoogleChartsLoaded() {
    google.charts.load('current', {'packages':['timeline']})
    google.charts.setOnLoadCallback(() => this.drawChart())
  }

  drawChart() {
    const container = this.chartTarget
    this.chart = new google.visualization.Timeline(container)
    const dataTable = new google.visualization.DataTable()

    dataTable.addColumn({ type: 'string', id: 'Task' })
    dataTable.addColumn({ type: 'string', id: 'Agent' })
    dataTable.addColumn({ type: 'number', id: 'Start' })
    dataTable.addColumn({ type: 'number', id: 'End' })

    const startTime = Math.min(...this.tasksValue.map(task => new Date(task.created_at).getTime()))

    this.tasksValue.forEach(task => {
      const relativeStart = new Date(task.created_at).getTime() - startTime
      const relativeEnd = relativeStart + (task.execution_time * 1000)
      dataTable.addRow([
        task.task.substring(0, 30) + '...', // Truncate long descriptions
        task.agent,
        relativeStart,
        relativeEnd
      ])
    })

    const options = {
      timeline: {
        colorByRowLabel: false,
        showRowLabels: true,
        showBarLabels: false
      },
      avoidOverlappingGridLines: false,
      colors: ['#EF4444', '#F59E0B', '#10B981', '#3B82F6', '#6366F1', '#8B5CF6', '#EC4899'],
      backgroundColor: '#F3F4F6',
      hAxis: {
        format: '# s', // Display time in seconds
        title: 'Time (seconds)'
      }
    }

    this.chart.draw(dataTable, options)

    // Adjust the height of the chart container
    const chartHeight = (this.tasksValue.length * 41) + 50 // 41px per row + 50px for header
    container.style.height = `${chartHeight}px`
  }
}
