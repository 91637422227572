const getCsrfToken = () => document.querySelector('[name="csrf-token"]').content;

const headers = {
  'Content-Type': 'application/json',
  'X-CSRF-Token': getCsrfToken()
};

export const updateCrewPlan = async (crewId, planId, updatedPlan) => {
  console.log('Updating crew plan with planId:', planId);

  try {
    const response = await fetch(`/crewai_plus/ui_studio/${crewId}/update_crew_plan/${planId}`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ plan: updatedPlan })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Crew plan updated successfully:', data);
    return data;
  } catch (error) {
    console.error('Error updating crew plan:', error);
    throw error;
  }
};

export const fetchCrewPlan = async (crewId, planId) => {
  try {
    const response = await fetch(`/crewai_plus/ui_studio/${crewId}/get_crew_plan/${planId}`, {
      method: 'GET',
      headers
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching crew plan:', error);
    throw error;
  }
};

export const generateCrewPlan = async (chatId) => {
  try {
    const response = await fetch('/crewai_plus/ui_studio/create_crew_plan', {
      method: 'POST',
      headers,
      body: JSON.stringify({ chat_id: chatId })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Crew plan generated successfully:', data);
    return data;
  } catch (error) {
    console.error('Error generating crew plan:', error);
    throw error;
  }
};

export const executePlan = async (chatId, planId) => {
  try {
    const response = await fetch(`/crewai_plus/ui_studio/${chatId}/create_crew/${planId}`, {
      method: 'POST',
      headers
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Plan executed successfully:', data);
    return data;
  } catch (error) {
    console.error('Error executing plan:', error);
    throw error;
  }
};
