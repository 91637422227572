// components/Card.jsx
import React from 'react';
import { Draggable } from '@hello-pangea/dnd';
import { formatDistanceToNow } from 'date-fns';

const Card = ({ card, index, deleteCard, isDraggable, onClick, columnId }) => {
  const formattedStartedAt = card.started_at
    ? formatDistanceToNow(new Date(card.started_at), { addSuffix: true })
    : '';

  const cardContent = (
    <div
      className="
        overflow-hidden rounded-xl border border-gray-200 pb-5 transition-all
        duration-300 ease-in-out hover:shadow-lg hover:scale-[1.01]
        hover:border-primary-color flex flex-col
        bg-white p-3 mb-2 cursor-pointer w-[95%] mx-auto mt-0.5
        relative
      "
      onClick={() => onClick(card.id)}
    >
      <div className="flex flex-col relative justify-center items-start">
        <div dangerouslySetInnerHTML={{ __html: card.content }} />
        {formattedStartedAt && (
          <div className="flex items-center text-xs text-gray-500 mt-3">
            <svg className="w-4 h-4 mr-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12" r="9" stroke="currentColor" strokeWidth="2"/>
              <path d="M12 7V12L15 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
            </svg>
            <span className="text-xs italic">{formattedStartedAt}</span>
          </div>
        )}
      </div>
      {columnId === 'doing' && (
        <div className="absolute top-1 right-1 animate-spin z-1">
          <svg className="w-5 h-5 text-primary-color" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      )}
    </div>
  );

  if (!isDraggable) {
    return cardContent;
  }

  return (
    <Draggable draggableId={card.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {cardContent}
        </div>
      )}
    </Draggable>
  );
};

export default Card;
