import { Controller } from "@hotwired/stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static targets = ["timeToFirstDeploy", "crewsPerAccount", "executionsPerAccount", "usersPerOrg"]
  static values = { timeRange: String, plan: String }

  connect() {
    this.fetchData()
  }

  async fetchData() {
    try {
      const response = await fetch(`/admin/dashboard/chart_data?time_range=${this.timeRangeValue}&plan=${this.planValue}`)
      const data = await response.json()
      this.renderCharts(data)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  renderCharts(data) {
    if (this.isAllDataNull(data)) {
      this.showNoDataMessage()
    } else {
      this.renderChart(this.timeToFirstDeployTarget, "Time to First Deploy (hours)", data.time_to_first_deploy)
      this.renderChart(this.crewsPerAccountTarget, "Crews per Account", data.crews_per_account)
      this.renderChart(this.executionsPerAccountTarget, "Executions per Account", data.executions_per_account)
      this.renderChart(this.usersPerOrgTarget, "Users per Organization", data.users_per_org)
    }
  }

  isAllDataNull(data) {
    return Object.values(data).every(value => value === null)
  }

  showNoDataMessage() {
    const message = '<p class="text-center text-gray-500 py-4">No data available for the selected time range and plan.</p>'
    this.element.innerHTML = message
  }

  renderChart(target, title, data) {
    if (!data || data.length === 0) {
      target.innerHTML = '<p class="text-center text-gray-500">No data available</p>';
      return;
    }

    const options = {
      series: [{
        name: title,
        data: data.map(item => item.y)
      }],
      chart: {
        type: 'line',
        height: 250,
        toolbar: {
          show: false
        }
      },
      xaxis: {
        categories: data.map(item => item.x),
        type: 'datetime'
      },
      yaxis: {
        title: {
          text: title
        }
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy'
        }
      }
    }

    new ApexCharts(target, options).render()
  }
}
