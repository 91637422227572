import { Controller } from "@hotwired/stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static targets = ["avgTokens", "avgExecutionTime", "totalTokens", "totalExecutionTime", "totalExecutions", "totalTokensLastWeekPercentageChange", "averageTokensLastWeekPercentageChange", "averagePromptTokensChart", "averageExecutionTimeChart", "totalPromptTokensChart", "totalExecutionTimeChart", "totalExecutionsChart", "totalExecutionsLastWeekPercentageChange", "averageExecutionTimeLastWeekPercentageChange"]
  static values = { deploymentId: Number }

  connect() {
    const initialParams = this.getInitialParams()
    this.fetchDataLastWeek(initialParams)
  }

  async fetchDataSummary(params) {
    const response = await fetch(`/crewai_plus/deployments/${this.deploymentIdValue}/crew_executions_data/summary?${new URLSearchParams(params)}`)
    return await response.json()
  }

  async fetchDataSummaryLastWeek(params) {
    const response = await fetch(`/crewai_plus/deployments/${this.deploymentIdValue}/crew_executions_data/summary_last_week?${new URLSearchParams(params)}`)
    return await response.json()
  }

  formatLargeNumber(num) {
    if (num >= 1e9) {
      return (num / 1e9).toFixed(1) + 'B';
    }
    if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + 'M';
    }
    return num.toLocaleString();
  }

  async fetchDataLastWeek(params) {
    const dataSummaryLastWeek = await this.fetchDataSummaryLastWeek(params)
    this.totalTokensLastWeekPercentageChangeTarget.innerHTML = this.formatPercentageChange(dataSummaryLastWeek.total_tokens_last_week_percentage_change)
    this.averageExecutionTimeLastWeekPercentageChangeTarget.innerHTML = this.formatPercentageChange(dataSummaryLastWeek.average_execution_time_last_week_percentage_change)
    this.totalExecutionsLastWeekPercentageChangeTarget.innerHTML = this.formatPercentageChange(dataSummaryLastWeek.total_executions_last_week_percentage_change)
    this.averageTokensLastWeekPercentageChangeTarget.innerHTML = this.formatPercentageChange(dataSummaryLastWeek.average_tokens_last_week_percentage_change)

    this.renderAveragePromptTokensChart(dataSummaryLastWeek.total_tokens_last_week)
    this.renderAverageExecutionTimeChart(dataSummaryLastWeek.total_execution_time_last_week)
    this.renderTotalPromptTokensChart(dataSummaryLastWeek.total_tokens_last_week)
    this.renderTotalExecutionsChart(dataSummaryLastWeek.execution_run_types_last_week)

    this.updateSummaryBoxes(dataSummaryLastWeek)
  }

  updateSummaryBoxes(dataSummaryLastWeek) {
    this.avgTokensTarget.textContent = this.formatLargeNumber(Math.round(dataSummaryLastWeek.average_tokens))
    this.avgExecutionTimeTarget.textContent = this.formatLargeNumber(Math.round(dataSummaryLastWeek.average_execution_time))
    this.totalTokensTarget.textContent = this.formatLargeNumber(Math.round(dataSummaryLastWeek.total_tokens))
    this.totalExecutionsTarget.textContent = dataSummaryLastWeek.total_executions.toLocaleString()
  }

  formatPercentageChange(value) {
    if (typeof value === 'string') {
      value = parseFloat(value)
    }
    const isPositive = value >= 0
    const colorClass = isPositive ? 'text-green-500' : 'text-red-500'
    const arrowSVG = isPositive ? this.getUpArrowSVG() : this.getDownArrowSVG()
    const formattedValue = Math.abs(value).toLocaleString() + '%'

    return `
      <span class="${colorClass}">${formattedValue}</span>
      ${arrowSVG}
    `
  }

  getUpArrowSVG() {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline ml-1" viewBox="0 0 20 20" fill="green">
        <path fill-rule="evenodd" d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
      </svg>
    `;
  }

  getDownArrowSVG() {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline ml-1" viewBox="0 0 20 20" fill="red">
        <path fill-rule="evenodd" d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z" clip-rule="evenodd" />
      </svg>
    `
  }

  renderAveragePromptTokensChart(data) {
    const dates = data.map(([date, _]) => date);
    const values = data.map(([_, value]) => value);
    const options = {
      chart: {
        type: 'area',
        height: 200,
        toolbar: {
          show: false
        },
      },
      series: [{
        name: 'Average Tokens',
        data: values
      }],
      xaxis: {
        type: 'datetime',
        categories: dates,
        labels: {
          format: 'dd MMM',
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return Math.round(value)
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      colors: ['#EB6658'],
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.3,
          stops: [0, 90, 100]
        }
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy'
        },
        style: {
          fontSize: '8px',
        }
      }
    }
    const chart_target = document.getElementById("average-prompt-tokens-chart")
    if (chart_target && typeof ApexCharts !== 'undefined') {
      const chart = new ApexCharts(chart_target, options)
      chart.render()
    }
  }

  renderAverageExecutionTimeChart(data) {
    const dates = data.map(([date, _]) => date);
    const values = data.map(([_, value]) => value);

    const options = {
      chart: {
        type: 'bar',
        height: 200,
        toolbar: {
          show: false
        },
      },
      series: [{
        name: 'Average Execution Time',
        data: values
      }],
      xaxis: {
        type: 'datetime',
        categories: dates,
        labels: {
          format: 'dd MMM',
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return Math.round(value)
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#EB6658'],
      plotOptions: {
        bar: {
          borderRadius: 4,
          columnWidth: '70%',
        }
      }
    }
    const chart_target = document.getElementById("average-execution-time-chart")
    if (chart_target && typeof ApexCharts !== 'undefined') {
      const chart = new ApexCharts(chart_target, options)
      chart.render()
    }
  }

  renderTotalPromptTokensChart(data) {
    const dates = data.map(([date, _]) => date);
    const values = data.map(([_, value]) => value);
    const options = {
      chart: {
        type: 'bar',
        height: 200,
        toolbar: {
          show: false
        },
      },
      series: [{
        name: 'Total Prompt Tokens',
        data: values
      }],
      xaxis: {
        type: 'datetime',
        categories: dates,
        labels: {
          format: 'dd MMM',
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return Math.round(value)
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#EB6658'],
      plotOptions: {
        bar: {
          borderRadius: 4,
          columnWidth: '70%',
        }
      }
    }
    const chart_target = document.getElementById("total-prompt-tokens-chart")
    if (chart_target && typeof ApexCharts !== 'undefined') {
      const chart = new ApexCharts(chart_target, options)
      chart.render()
    }
  }

  renderTotalExecutionsChart(data) {
    const keys = data.map(([key, _]) => key);
    const values = data.map(([_, value]) => value);
    const options = {
      chart: {
        type: 'pie',
        height: 200,
        toolbar: {
          show: false
        },
      },
      series: values,
      labels: keys,
      colors: ['#EB6658', '#262626', '#3B82F6'],
      legend: {
        position: 'bottom'
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    };

    const chart_target = document.getElementById("total-executions-chart");
    if (chart_target && typeof ApexCharts !== 'undefined') {
      const chart = new ApexCharts(chart_target, options);
      chart.render();
    }
  }

  getInitialParams() {
    return {
      timeframe: document.querySelector('[data-crew-dashboard--chart-filter-delegator-target="timeframe"]').value,
      period: document.querySelector('[data-crew-dashboard--chart-filter-delegator-target="period"]').value,
      distribution: document.querySelector('[data-crew-dashboard--chart-filter-delegator-target="distribution"]').value,
      start_date: document.querySelector('[data-crew-dashboard--chart-filter-delegator-target="customStartDate"]')?.value,
      end_date: document.querySelector('[data-crew-dashboard--chart-filter-delegator-target="customEndDate"]')?.value,
      aggregation: document.querySelector('[data-crew-dashboard--chart-filter-delegator-target="aggregation"]').value,
    }
  }
}
