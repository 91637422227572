import { Controller } from "@hotwired/stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static targets = ["table", "totalSavings", "totalMinutesBeforeAutomation", "totalMinutesAfterAutomation", "overallChart", "taskChart"]
  static values = { deploymentId: Number }

  connect() {
    this.fetchRoiData()
  }

  fetchRoiData() {
    fetch(`/crewai_plus/deployments/${this.deploymentIdValue}/crew_executions_tasks_data/roi`)
      .then(response => response.json())
      .then(data => {
        this.renderRoiTable(data)
        this.fillSummary(data)
        this.renderCharts(data)
      })
  }

  renderRoiTable(data) {
    const tableHtml = `
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 m-0">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" class="px-6 py-3">Description</th>
            <th scope="col" class="px-6 py-3">Total time before automation</th>
            <th scope="col" class="px-6 py-3">Total time after automation</th>
            <th scope="col" class="px-6 py-3">Total time saved</th>
          </tr>
        </thead>
        <tbody>
          ${data.results.map(task => `
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700 transition duration-150 ease-in-out">
              <td class="px-6 py-2">${task.task_description.substring(0, 100)}${task.task_description.length > 100 ? '...' : ''}</td>
              <td class="px-6 py-2">${task.total_manual_cost_in_minutes_formatted}</td>
              <td class="px-6 py-2">${task.total_execution_time_in_minutes_formatted}</td>
              <td class="px-6 py-2">${task.roi_formatted}</td>
            </tr>
          `).join('')}
        </tbody>
      </table>
    `;
    this.tableTarget.innerHTML = tableHtml;
  }

  fillSummary(data) {
    this.totalSavingsTarget.textContent = data.total_savings_formatted
    this.totalMinutesBeforeAutomationTarget.textContent = data.total_minutes_before_automation_formatted
    this.totalMinutesAfterAutomationTarget.textContent = data.total_minutes_after_automation_formatted
  }

  renderCharts(data) {
    this.renderOverallChart(data)
    this.renderTaskChart(data)
  }

  renderOverallChart(data) {
    const options = {
      series: [{
        name: 'Minutes',
        data: [
          parseFloat(data.total_minutes_before_automation),
          parseFloat(data.total_minutes_after_automation)
        ]
      }],
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          const formattedValues = [
            data.total_minutes_before_automation_formatted,
            data.total_minutes_after_automation_formatted
          ];
          return formattedValues[opts.dataPointIndex];
        },
        style: {
          colors: ['#fff'],
          fontSize: '12px',
          fontWeight: 'bold',
          textShadow: '0px 0px 2px rgba(0, 0, 0, 0.8)'
        },
        background: {
          enabled: true,
          foreColor: '#000',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
        },
        offsetY: -20,
        position: 'top',
        distributed: true,
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 5,
          opacity: 0.1
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: ['Before Automation', 'After Automation'],
      },
      yaxis: {
        title: {
          text: 'Minutes'
        },
        labels: {
          formatter: function (val) {
            return Math.round(val)
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val, opts) {
            const formattedValues = [
              data.total_minutes_before_automation_formatted,
              data.total_minutes_after_automation_formatted
            ];
            return formattedValues[opts.dataPointIndex];
          }
        }
      },
      colors: ['#EB6658', '#737373']
    };

    const chart = new ApexCharts(this.overallChartTarget, options);
    chart.render();
  }

  renderTaskChart(data) {
    const taskData = data.results.map(task => ({
      x: task.task_description.substring(0, 30) + '...',
      y: [
        parseFloat(task.total_manual_cost_in_minutes),
        parseFloat(task.total_execution_time_in_minutes)
      ],
      formattedY: [
        task.total_manual_cost_in_minutes_formatted,
        task.total_execution_time_in_minutes_formatted
      ]
    }));

    const options = {
      series: [{
        name: 'Before Automation',
        data: taskData.map(task => task.y[0])
      }, {
        name: 'After Automation',
        data: taskData.map(task => task.y[1])
      }],
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return taskData[opts.dataPointIndex].formattedY[opts.seriesIndex];
        },
        style: {
          colors: ['#fff'],
          fontSize: '12px',
          fontWeight: 'bold',
          textShadow: '0px 0px 2px rgba(0, 0, 0, 0.8)'
        },
        background: {
          enabled: true,
          foreColor: '#000',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
        },
        offsetY: -20,
        position: 'top',
        distributed: true,
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 5,
          opacity: 0.1
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: taskData.map(task => task.x),
        labels: {
          rotate: -45,
          trim: true,
          maxHeight: 120
        }
      },
      yaxis: {
        title: {
          text: 'Minutes'
        },
        labels: {
          formatter: function (val) {
            return Math.round(val)
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val, opts) {
            return taskData[opts.dataPointIndex].formattedY[opts.seriesIndex];
          }
        }
      },
      colors: ['#EB6658', '#737373'],
      legend: {
        show: false
      }
    };

    const chart = new ApexCharts(this.taskChartTarget, options);
    chart.render();
  }

  refresh() {
    this.fetchRoiData()
  }
}
